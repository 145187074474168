import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
import { EnrollmentFieldsFragmentDoc } from '../../../gql/generated/enrollmentFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BilledAdvancedEnrollmentsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.RosterFilters>;
  sort?: Types.InputMaybe<Types.RosterSort>;
}>;

export type BilledAdvancedEnrollmentsQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              status: Types.EnrollmentStatusEnum;
              rosterPeriod?: string | null | undefined;
              billingDescription?: string | null | undefined;
              billPeriod?: string | null | undefined;
              billedDate?: string | null | undefined;
              paymentService?: Types.PaymentService | null | undefined;
              paymentStatus: Types.PaymentStatus;
              paymentFailureReason?: string | null | undefined;
              perSessionRefunds: boolean;
              refundsTotal: number;
              id: string;
              createdAt: string;
              discountAmount: number;
              discountCodes: Array<string>;
              balance: number;
              pickup?: string | null | undefined;
              dismissal?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              priceConfig?:
                | { billingDate?: string | null | undefined }
                | { billingDate?: string | null | undefined }
                | { billingDate?: string | null | undefined }
                | { billingDate?: string | null | undefined }
                | null
                | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
              enrollmentPriceTierUse?: { cartPriceTierUse: { id: string; name: string } } | null | undefined;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    firstNameWithNickname: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    dob?: string | null | undefined;
                    nickname?: string | null | undefined;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const BilledAdvancedEnrollmentsDocument = gql`
  query BilledAdvancedEnrollments($groupingId: ID!, $filters: RosterFilters, $sort: RosterSort) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: COURSE, enrollmentStatus: BILLED, filters: $filters, sort: $sort) {
      total
      items {
        ...enrollmentFields
        status
        rosterPeriod
        billingDescription
        billPeriod
        billedDate
        paymentService
        paymentStatus
        paymentFailureReason
        perSessionRefunds
        refundsTotal
        priceConfig {
          billingDate
        }
      }
    }
  }
  ${EnrollmentFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type BilledAdvancedEnrollmentsArgs = Omit<UseQueryArgs<BilledAdvancedEnrollmentsQueryVariables, BilledAdvancedEnrollmentsQuery>, 'query'>;

export function useBilledAdvancedEnrollmentsQuery(options: BilledAdvancedEnrollmentsArgs = {}) {
  return useQuery<BilledAdvancedEnrollmentsQueryVariables, BilledAdvancedEnrollmentsQuery>({ query: BilledAdvancedEnrollmentsDocument, ...options });
}

export type BilledAdvancedEnrollmentsOptions = ExecQueryOptions<BilledAdvancedEnrollmentsQueryVariables>;

export function billedAdvancedEnrollments(options: BilledAdvancedEnrollmentsOptions) {
  return executeQuery<BilledAdvancedEnrollmentsQuery, BilledAdvancedEnrollmentsQueryVariables>(BilledAdvancedEnrollmentsDocument, options);
}

export type BilledAdvancedEnrollmentsSelections = BilledAdvancedEnrollmentsQuery['rosterByGrouping']['items'][0];

(BilledAdvancedEnrollmentsDocument as any).additionalTypenames = ['RosterItem', 'Enrollment'];
