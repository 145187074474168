import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface NameAndId {
  id:string;
  student: {
    name:string;
  }
}

interface Props {
  info?:FieldInfo<NameAndId>;
  enrollment?:NameAndId;
}

export function EnrollmentLink(props:Props) {
  const enrollment = props.enrollment || props.info.record;

  return <Link className='hr-value' text='body' to={`/admin2/enrollments/${enrollment?.id}`}>{enrollment?.student?.name} ({enrollment.id})</Link>
}

EnrollmentLink.fieldProps = {
  fieldProperty: 'info',
}