import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type ReportTransactionsCommonFragment = {
  rosterPeriod?: string | null | undefined;
  billPeriod?: string | null | undefined;
  companyFee?: number | null | undefined;
  discountAmount?: number | null | undefined;
  discountCodes?: Array<string> | null | undefined;
  trackingParameters?: string | null | undefined;
  id?: string | null | undefined;
  transactionDate?: string | null | undefined;
  transactionStatus?: string | null | undefined;
  transactionDescription?: string | null | undefined;
  paymentService?: Types.PaymentService | null | undefined;
  companyEarnings?: number | null | undefined;
  companyHomeroomFee?: number | null | undefined;
  companyProcessingFee?: number | null | undefined;
  companyInternalTransferDate?: string | null | undefined;
  companyExternalTransferDate?: string | null | undefined;
  companyExternalTransferEstimatedDate?: string | null | undefined;
  companyExternalTransferAmount?: number | null | undefined;
  companyExternalTransferStatus?: string | null | undefined;
  site?: { id: string; name?: string | null | undefined } | null | undefined;
  season?: { id: string; name: string; formattedKind?: string | null | undefined } | null | undefined;
  company?: { id: string; name?: string | null | undefined } | null | undefined;
  course?:
    | {
        id: string;
        name?: string | null | undefined;
        grades?: Array<string | null | undefined> | null | undefined;
        siteId: string;
        status: Types.CourseStatus;
        startDate?: string | null | undefined;
        endDate?: string | null | undefined;
        classesCount: number;
      }
    | null
    | undefined;
  student?: { id: string; nameWithNickname: string; grade: string; classroom?: { siteId: string } | null | undefined } | null | undefined;
  parent?: { id: string; name?: string | null | undefined; email: string } | null | undefined;
};

export const ReportTransactionsCommonFragmentDoc = gql`
  fragment reportTransactionsCommon on ReportTransaction {
    site {
      id
      name
    }
    season {
      id
      name
      formattedKind
    }
    company {
      id
      name
    }
    course {
      id
      name
      grades
      siteId
      status
      startDate
      endDate
      classesCount
    }
    student {
      id
      nameWithNickname
      grade
      classroom {
        siteId
      }
    }
    parent {
      id
      name
      email
    }
    rosterPeriod
    billPeriod
    companyFee
    discountAmount
    discountCodes
    trackingParameters
    id
    transactionDate
    transactionStatus
    transactionDescription
    paymentService
    companyEarnings
    companyHomeroomFee
    companyProcessingFee
    companyInternalTransferDate
    companyExternalTransferDate
    companyExternalTransferEstimatedDate
    companyExternalTransferAmount
    companyExternalTransferStatus
  }
`;
