import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
import { EnrollmentFieldsFragmentDoc } from '../../../gql/generated/enrollmentFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BilledLaterAdvancedEnrollmentsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.RosterFilters>;
  sort?: Types.InputMaybe<Types.RosterSort>;
}>;

export type BilledLaterAdvancedEnrollmentsQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              billingDescription?: string | null | undefined;
              billPeriod?: string | null | undefined;
              rosterPeriod?: string | null | undefined;
              id: string;
              createdAt: string;
              discountAmount: number;
              discountCodes: Array<string>;
              balance: number;
              pickup?: string | null | undefined;
              dismissal?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              additionalDiscount?: { rate?: number | null | undefined; format?: Types.DiscountFormat | null | undefined } | null | undefined;
              priceConfig?:
                | { billingDate?: string | null | undefined }
                | { billingDate?: string | null | undefined }
                | { billingDate?: string | null | undefined }
                | { billingDate?: string | null | undefined }
                | null
                | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
              enrollmentPriceTierUse?: { cartPriceTierUse: { id: string; name: string } } | null | undefined;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    firstNameWithNickname: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    dob?: string | null | undefined;
                    nickname?: string | null | undefined;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const BilledLaterAdvancedEnrollmentsDocument = gql`
  query BilledLaterAdvancedEnrollments($groupingId: ID!, $filters: RosterFilters, $sort: RosterSort) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: COURSE, enrollmentStatus: BILLED_LATER_ADVANCED, filters: $filters, sort: $sort) {
      total
      items {
        ...enrollmentFields
        billingDescription
        billPeriod
        rosterPeriod
        additionalDiscount {
          rate
          format
        }
        priceConfig {
          billingDate
        }
      }
    }
  }
  ${EnrollmentFieldsFragmentDoc}
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type BilledLaterAdvancedEnrollmentsArgs = Omit<UseQueryArgs<BilledLaterAdvancedEnrollmentsQueryVariables, BilledLaterAdvancedEnrollmentsQuery>, 'query'>;

export function useBilledLaterAdvancedEnrollmentsQuery(options: BilledLaterAdvancedEnrollmentsArgs = {}) {
  return useQuery<BilledLaterAdvancedEnrollmentsQueryVariables, BilledLaterAdvancedEnrollmentsQuery>({ query: BilledLaterAdvancedEnrollmentsDocument, ...options });
}

export type BilledLaterAdvancedEnrollmentsOptions = ExecQueryOptions<BilledLaterAdvancedEnrollmentsQueryVariables>;

export function billedLaterAdvancedEnrollments(options: BilledLaterAdvancedEnrollmentsOptions) {
  return executeQuery<BilledLaterAdvancedEnrollmentsQuery, BilledLaterAdvancedEnrollmentsQueryVariables>(BilledLaterAdvancedEnrollmentsDocument, options);
}

export type BilledLaterAdvancedEnrollmentsSelections = BilledLaterAdvancedEnrollmentsQuery['rosterByGrouping']['items'][0];

(BilledLaterAdvancedEnrollmentsDocument as any).additionalTypenames = ['RosterItem', 'Enrollment'];
