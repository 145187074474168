import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as qs from 'query-string';
import pluralize from 'pluralize';

import {
  buildCloudinaryImageUrl,
  Heading1,
  Heading2,
  VBox,
  withBreakpoints,
  BreakpointProps,
  HBox,
  Subtitle2,
  buildBreadCrumbStr,
  LoadMore,
  Loader
} from 'app2/components';
import { observer } from 'app/observable';
import { eventManager } from 'app2/api';
import { sharedDataManager } from 'app/admin/api';
import { MarketAreaCategoryStore } from 'app2/api';
import { DEFAULT_HEADER_IMG } from 'app2/views/shared-public';

import { PublicPage, PageContent } from '../../shared-public';

import { CatalogCard } from './CatalogCard';

interface Props
  extends BreakpointProps,
    RouteComponentProps<{ marketAreaSlug: string; categorySlug: string }> {}

const pageSize = 99;

@observer
export class InnerMarketAreaCategory extends React.Component<Props> {
  store: MarketAreaCategoryStore = sharedDataManager.get(
    MarketAreaCategoryStore
  );

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(_: Props) {
    this.loadData();
  }

  async loadData() {
    try {
      const promises = this.store.load(
        this.props.match.params.marketAreaSlug,
        this.props.match.params.categorySlug
      );

      // we only load the first set of catalogs if the
      // slug has changed because we load all catalogs
      // all at once

      if (!promises.length) {
        return;
      }

      promises.push(this.store.loadCatalogPage(pageSize, this.page));

      await Promise.all(promises);

      this.logPageView();
    } catch (_) {}
  }

  logPageView() {
    eventManager.log('viewCityCategoryLandingPage', {
      url: window.location.href,
      breadCrumbs: buildBreadCrumbStr(this.crumbs),
      totalNumberOfCatalogListings: this.store.catalogs.pagination.count,
      totalNumberOfProviders: this.store.catalogs.meta.provider_count
    });
  }

  get url() {
    return `/in/${this.store.marketArea.slug}/category/${this.store.category.slug}`;
  }

  get page() {
    return Math.max(qs.parse(this.props.location.search).page || 0, 0);
  }

  get crumbs() {
    if (!this.store.category) {
      return [];
    }

    return [
      {
        label: this.store.marketArea.name,
        url: `/in/${this.store.marketArea.slug}`
      },
      {
        label: this.store.category.name,
        url: this.url
      }
    ];
  }

  render() {
    if (
      !this.store.marketArea ||
      !this.store.category ||
      !this.store.catalogs
    ) {
      return <Loader />;
    }

    const catalogs = this.store.catalogs;
    const numPerRow = [1, 2, 3][this.props.breakpoint];

    const title = `${this.store.marketArea.name}: ${this.store.category.name} Activities for Kids`;
    const description = `Discover local ${this.store.category.name} after school activities for kids near ${this.store.marketArea.name}`;

    return <PublicPage head={{title, description}} crumbs={this.crumbs} above={<Header store={this.store} />} useMaxWidth header footer>
      <HBox mb="$40" width="100%" vAlign="center">
        <HBox flex="1" />
        <Subtitle2 mr="$15">
          {catalogs.pagination.count}{' '}
          {pluralize('activity', catalogs.pagination.count)}
        </Subtitle2>
      </HBox>
      <LoadMore
        width="100%"
        numPages={catalogs.pagination.pages}
        numPerRow={numPerRow}
        load={this.loadCatalogs}
        component={CatalogCard}
        dataKey={
          this.store.marketAreaSlug + ':' + this.store.categorySlug
        }
      />
    </PublicPage>
  }

  loadCatalogs = async (page: number) => {
    const promise = this.store.loadCatalogPage(pageSize, page);

    if (promise) {
      await promise;
    }

    return this.store.catalogs.records;
  };
}

function Header(props: { store: MarketAreaCategoryStore }) {
  const imageURL = buildCloudinaryImageUrl(
    props.store.category.hero_img_url || DEFAULT_HEADER_IMG
  );

  return (
    <VBox mb="$30" position="relative" minHeight="150px">
      <VBox
        background={`url(${imageURL})`}
        backgroundSize="cover"
        width="100%"
        height="100%"
        position="absolute"
        zIndex={-2}
      />
      <PageContent pt="$15" pb="$15">
        <Heading1 color="#fff" mb="$15">
          {props.store.category.name} Activities for Kids in{' '}
          {props.store.marketArea.name}
        </Heading1>
        {props.store.category.heading_2 && (
          <Heading2 color="#fff">{props.store.category.heading_2}</Heading2>
        )}
      </PageContent>
    </VBox>
  );
}

export const MarketAreaCategory = withBreakpoints(
  withRouter(InnerMarketAreaCategory),
  ['635px', '1040px']
);
