import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as qs from 'query-string';
import pluralize from 'pluralize';

import {
  Heading1,
  Heading2,
  VBox,
  withBreakpoints,
  BreakpointProps,
  HBox,
  Subtitle2,
  buildBreadCrumbStr,
  LoadMore,
  Loader
} from 'app2/components';
import { observer } from 'app/observable';
import { eventManager } from 'app2/api';
import { sharedDataManager } from 'app/admin/api';
import { CategoryStore } from 'app2/api';
import { buildCloudinaryImageUrl } from 'app2/components';
import { DEFAULT_HEADER_IMG } from 'app2/views/shared-public';

import { PublicPage, PageContent } from '../../shared-public';

import { CatalogCard } from './CatalogCard';

const pageSize = 99;

interface Props
  extends BreakpointProps,
    RouteComponentProps<{ slug: string }> {}

@observer
export class InnerCategory extends React.Component<Props> {
  store: CategoryStore = sharedDataManager.get(CategoryStore);

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(_: Props) {
    this.loadData();
  }

  async loadData() {
    try {
      const promises = this.store.load(this.props.match.params.slug);

      // we only load the first set of catalogs if the
      // slug has changed because we load all catalogs
      // all at once

      if (!promises.length) {
        return;
      }

      promises.push(this.store.loadCategoryPage(pageSize, this.page));

      await Promise.all(promises);

      this.logPageView();
    } catch (_) {}
  }

  logPageView() {
    eventManager.log('viewCategoryLandingPage', {
      url: window.location.href,
      breadCrumbs: buildBreadCrumbStr(this.crumbs),
      totalNumberOfCatalogListings: this.store.catalogs.pagination.count,
      totalNumberOfProviders: this.store.catalogs.meta.provider_count
    });
  }

  get url() {
    return `/category/${this.store.category.slug}`;
  }

  get page() {
    return Math.max(qs.parse(this.props.location.search).page || 0, 0);
  }

  get crumbs() {
    if (!this.store.category) {
      return [];
    }

    return [
      {
        label: this.store.category.name,
        url: this.url
      }
    ];
  }

  render() {
    if (!this.store.category || !this.store.catalogs) {
      return <Loader />;
    }

    const catalogs = this.store.catalogs;
    const numPerRow = [1, 2, 3][this.props.breakpoint];

    const title = `Discover High-Quality ${this.store.category.name} After School Activities for Kids`;
    const description = `After school programs on Homeroom offer children access to high-quality, age-appropriate ${this.store.category.name} activities.`;

    return <PublicPage head={{title, description}} crumbs={this.crumbs} above={<Header store={this.store} />} useMaxWidth header footer>
      <HBox mb="$40" width="100%" vAlign="center">
        <HBox flex="1" />
        <Subtitle2 mr="$15">
          {catalogs.pagination.count}{' '}
          {pluralize('activity', catalogs.pagination.count)}
        </Subtitle2>
      </HBox>
      <LoadMore
        width="100%"
        numPages={catalogs.pagination.pages}
        numPerRow={numPerRow}
        load={this.loadCatalogs}
        component={CatalogCard}
        dataKey={this.props.match.params.slug}
      />
    </PublicPage>
  }

  loadCatalogs = async (page: number) => {
    const promise = this.store.loadCategoryPage(pageSize, page);

    if (promise) {
      await promise;
    }

    return this.store.catalogs.records;
  };
}

function Header(props: { store: CategoryStore }) {
  const imageURL = buildCloudinaryImageUrl(
    props.store.category.hero_img_url || DEFAULT_HEADER_IMG
  );

  return (
    <VBox mb="$30" position="relative" minHeight="150px">
      <VBox
        background={`url(${imageURL})`}
        backgroundSize="cover"
        width="100%"
        height="100%"
        position="absolute"
        zIndex={-2}
      />
      <PageContent pt="$15" pb="$15">
        <Heading1 color="#fff" mb="$15">
          {props.store.category.name} Activities for Kids
        </Heading1>
        {props.store.category.heading_2 && (
          <Heading2 color="#fff">{props.store.category.heading_2}</Heading2>
        )}
      </PageContent>
    </VBox>
  );
}

export const Category = withBreakpoints(withRouter(InnerCategory), [
  '635px',
  '1040px'
]);
