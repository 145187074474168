import * as React from 'react'
import { useParams } from 'react-router';

import { DateField, Field, Form, RepeatingSection } from 'app2/components';

import { EnrollmentLink, UserLink } from '../shared';

import { useAdminCourseEnrollmentsQuery } from './generated';

export function Enrollments() {
  const { course } = useParams<{course:string}>();

  const [result] = useAdminCourseEnrollmentsQuery({variables:{id:course}});
  const item = result.data?.course;

  function render() {
    return <Form initialValues={item} editing={false}>
      <RepeatingSection name='enrollments' fields={[
        <Field name='id' label='Student' component={EnrollmentLink} />,
        <Field name='createdAt' label='Created' component={DateField} />,
        <Field name='student.parents.0.name' label='Parent' component={UserLink} />,
        <Field name='status' label='Status' format={(value:string) => value == "PAID" ? "Enrolled" : value} />,
      ]} />
    </Form>
  }

  return render();
}
