import * as React from 'react';
import pluralize from 'pluralize';
import { O } from 'ts-toolbelt'

import { CourseKind, CourseWithOptions, CourseUtils, Course } from 'app2/api';
import { CancelConfirmationField, Modal, RepeatingSection, Info, Form, Field, FormModel, Section } from 'app2/components';
import { errorPathTransform } from 'app2/views/shared';
import { courseKindBehavior } from 'app2/views/shared-public';

import { cancelOrDeleteCourses } from './generated';

interface FormValues {
  ids: string[];
  includeOptions:boolean;
}

type CancelCourseForm = FormModel<FormValues & Props>;

interface Props {
  courses: (O.P.Pick<Course, ['id' | 'status' | 'kind', 'id']> & CourseWithOptions)[];
  showDelete?: boolean;
}

export function CancelCoursesModal(props: Props) {
  const showDelete = props.showDelete;

  function render() {
    const hasOptions = props.courses.some(c => CourseUtils.hasOptions(c));
    const verb = showDelete ? 'Delete' : 'Cancel'
    const modalTitle = `${verb} activity`;

    return (
      <Modal title={modalTitle}>
        <Form initialValues={props} onOk={handleSubmit} onNavigation='nothing'>
          <RepeatingSection name="courses" bordered fields={[<Field label="Activities" name="name" />]} />
          {renderWarnings()}
          <Section name='cancel' component={CancelConfirmationField} />
          {/* {hasOptions && <Part component={CheckboxField} label={`${verb} activity options`} name="includeOptions" />} */}
        </Form>
      </Modal>
    );
  }

  function renderWarnings() {
    const isCancelableSelected = props.courses.some(c => !CourseUtils.isDeletable(c));
    const refundable = props.courses.some(c => courseKindBehavior[c.kind].registerable);
    const messages = (isCancelableSelected ? (refundable ? cancelWithRefundMessage : cancelWithoutRefundMessage) : deleteMsg).slice();

    const published = props.courses.some(c => CourseUtils.isScheduled(c));

    if (published &&  props.courses.some(c => c.kind === CourseKind.AthleticReg)) {
      messages.push('Cancelling a team fee does not cancel the team.');
    }

    if (published && props.courses.some(c => c.kind === CourseKind.AthleticIndividualReg)) {
      messages.push('Cancelling an individual fee does not remove students from their team.');
    }

    if (published && props.courses.some(c => c.kind === CourseKind.Team)) {
      messages.push('Cancelling a team activity will not refund any registration fees.');
    }

    return <Info type="warning">{messages}</Info>
  }

  async function handleSubmit(form: CancelCourseForm) {
    const ids = props.courses.map(c => c.id)
    const msg = `${pluralize('Activity', ids.length)} ${showDelete ? 'deleted' : 'canceled'}`
    const includeOptions = form.values.includeOptions;
    const [success] = await cancelOrDeleteCourses({ variables: { ids, includeOptions }, error:{handler: form, transform: errorPathTransform('ids', 'courses')}, successMsg: msg });
    return success;
  }

  return render();
}

const cancelWithRefundMessage = [
  "This action cannot be undone. If you continue, the students enrolled in the activities listed above will be removed and the parents will be refunded in full.",
  "Please allow up to 15 minutes for changes to be reflected on the Activity page."
]

const cancelWithoutRefundMessage = [
  "This action cannot be undone. If you continue, the students enrolled in the activities listed above will be removed.",
  "Please allow up to 15 minutes for changes to be reflected on the Activity page."
]

const deleteMsg = ["This action cannot be undone. If you continue, selected activities will be deleted and cannot be recovered later."]
