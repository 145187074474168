import * as React from 'react';
import { useParams } from 'react-router';

import { BooleanLabelField, DateField, Panel, Section, useForm } from 'app2/components';

import { CompanyLink, CourseLink, StudentLink, UserLink } from '../shared';

import { useAdminEnrollmentQuery } from './generated';

export function EnrollmentInfo() {
  const { id } = useParams<{id:string}>();
  const [result] = useAdminEnrollmentQuery({variables: {id}});
  const item = result.data?.enrollment;
  const form = useForm(item, [item]);

  function render() {
    return <Panel icon='Home' title='Information' form={form}>
      <Section label="ID" name="id" readOnly />
      <Section label="Student" name="student.name" component={StudentLink} />
      <Section label='Activity' name='course.name' component={CourseLink} />
      <Section label='Provider' name='company.id' component={CompanyLink} />
      <Section label='Parent' name='parent.name' component={UserLink} />
      <Section label='Schedule' name='rosterPeriod' />
      <Section label='Purchase price' name='discountedPrice' />
      <Section label='Purchased' name='purchased' component={BooleanLabelField} />
      <Section label='Start' name='startDate' component={DateField} />
      <Section label='Type' name='priceConfig.kind' />
    </Panel>
  }

  return render();
}
