import * as React from 'react'
import moment from 'moment'

import { BoxProps, HBox, VBox } from '../Box'
import { Icon } from '../icons'
import { Dropdown } from '../Dropdown'
import { theme, colorToComponent, blendColor, componentToHex, BreakpointInfo } from '../theme'
import { dispatchChangeEvent } from '../dom-utils'
import { isValidDate, sortDates, DateInput, datesBetween, parseSingleDate, toTz } from '../date-utils'
import { MultiContext } from '../utils';
import { getAppMode } from '../AppModes';

import { Legend, LegendItem } from './Legend'
import { compareDateArrays, compareDates, years, months } from './utils'

export const DATE_VALUE_FORMAT = 'YYYY-MM-DD';
export const DATE_STYLE_MAP_KEY_FORMAT = 'YYYY-MM-DD';
type DayStyles = {name?:string, outer?:BoxProps, inner?:BoxProps};
type DayStylesMap = {[index: string]: DayStyles | BoxProps};
type DayStylesMapState = {[index: string]: DayStyles};
export enum DateValueFormat {guess = 0, date = 1, dateTime = 2};

export interface CalendarProps {
  type?:'single' | 'multiple' | 'range' | 'range-start' | 'range-end';
  // when allowing multiple, dictates if enter should toggle an entry on/off,
  // defaults to false, click always toggles, delete can be used to delete
  // entries
  enterToggles?:boolean;
  selected?:DateInput | DateInput[];
  // by default the calendar starts at today if there's no value, but you can
  // override that this will not change if there's a value
  start?:DateInput;
  legend?:(LegendItem & {days?:DateInput[], start?:DateInput, end?:DateInput})[];
  onChange?: React.ChangeEventHandler<Calendar>;

  // for custom styling of specific days
  // that isn't tied to a legend
  // you can provide an object where the
  // keys are the date you want to style
  // and the value is the styles
  dayStyles?:DayStylesMap;
  width?:string | number | string[] | number[];
  timezone?: string;
  // the calendar is capable of working with both
  // dates (via string) and date-times (via Date or Moment)
  // by default it will use 'date' when there's no timezone
  // and date-time (Moment) when there's a timezone.  note that
  // having a timezone and a value format of date will result
  // in buggy behavior.
  valueFormat?:DateValueFormat;
  min?:DateInput | 'now';
  max?:DateInput | 'now';

  // see dropdown explanation
  disablePhoneInput?:boolean;

  // if true, this allows the days of the week 
  // to be clickable and selectable
  daysOfWeek?:boolean;
  // because the selected api was designed for dates
  // there's an alternative selected api for days of the week
  dayOfWeek?:string;
}

interface State {
  current:moment.Moment;
  selected?:moment.Moment[];
  dayOfWeek?:string;
  dayStyles?:DayStylesMapState;
}

interface DayRenderInfo {
  label:string;
  date?:moment.Moment;
  styles?:DayStyles;
  outside?:boolean;// days that fall in the week windows but outside the month
  before?:boolean;// before the min
  after?:boolean;// after the max
}

const MAX_WIDTH = 362;

export class Calendar extends React.Component<CalendarProps> {
  static defaultProps:CalendarProps = {
    type: 'single',
    disablePhoneInput: true,
    valueFormat: DateValueFormat.guess
  }

  static contextType = MultiContext;
  context:BreakpointInfo;

  state:State = {
    current: null,
    selected: []
  }

  ref = React.createRef<HTMLElement>();
  py:number;
  px:number;
  boxInset:string;
  boxPadding:string;
  boxInside:string;
  min:moment.Moment;
  max:moment.Moment;
  filteredYears:typeof years;

  constructor(props:CalendarProps) {
    super(props);
    this.updateMinMax();
    this.state.current = this.startOfToday;
    this.state.dayStyles = this.createDayStyles();
    this.state = {...this.state, ...this.createDateState(Array.isArray(props.selected) ? props.selected : [props.selected], props.dayOfWeek)};

    if (props.start) {
      this.state.current = parseSingleDate(props.start, this.props.timezone).startOf('d').tz('utc');
    }
    
    this.state.current = this.restrictToMinMax(this.state.current || this.startOfToday);
    validateValueFormat(this.props);
  }

  get startOfToday() {
    return toTz(moment(), this.props.timezone).startOf('d').tz('utc');
  }

  get endOfToday() {
    return toTz(moment(), this.props.timezone).startOf('d').tz('utc');
  }

  get currentInTz() {
    return this.state.current.clone().tz(this.props.timezone || moment.tz.guess());
  }

  get element() {
    return this.ref.current;
  }

  get calendarElement() {
    return this.props.legend ? this.ref.current?.firstElementChild : this.ref.current;
  }

  get value():DateInput | DateInput[] {
    return formatDateValue(this.props, this.state.selected)
  }

  get dayOfWeek() {
    return this.state.dayOfWeek;
  }

  get controlled() {
    return this.props.selected !== undefined || this.props.legend !== undefined || this.props.dayStyles !== undefined;
  }

  get isPercentWidth() {
    const width = this.getPropsWidth(false);
    return typeof width == 'string' && width.endsWith('%');
  }

  getPropsWidth(treatPercentAsZero:boolean) {
    if (!this.props.width) {
      return 0;
    }

    const width = Array.isArray(this.props.width) ? this.props.width[this.context.deviceBreakpoint] : this.props.width;

    if (!width) {
      return 0;
    }

    if (typeof width == 'string' && width.endsWith('%')) {
      return treatPercentAsZero ? 0 : width;
    }

    return parseInt(width as any);
  }

  calculateDimensions() {
    const border = 1;
    const baseWithBorder = MAX_WIDTH;
    const base = baseWithBorder - (border * 2);
    const width = ((this.getPropsWidth(true) as number) || this.calendarElement?.getBoundingClientRect?.()?.width || baseWithBorder) - (border * 2);
    this.px = (width / base) * 18;
    this.py = (width / base) * 30;

    const insideWidth = (width - (this.px * 2));
    const box = (insideWidth / 7);
    const baseInsideWidth = (base - (this.px * 2));
    const baseBox = (baseInsideWidth / 7);
    this.boxInset = '-' + (box * (10 / baseBox)) + 'px';
    this.boxPadding = (box * (18 / baseBox)) + 'px';
    this.boxInside = (box * (30 / baseBox)) + 'px';
  }

  componentDidMount() {
    // if we were passed a percent width, we
    // need to rerender now that we have a ref
    if (this.isPercentWidth) {
      this.setState({});
    }
  }

  componentDidUpdate(prevProps:CalendarProps, prevState:State) {
    const timezoneChanged = this.props.timezone != prevProps.timezone;

    if (this.props.min != prevProps.min || this.props.max != prevProps.max || timezoneChanged) {
      this.updateMinMax();
      this.forceUpdate();
    }

    if (this.props.legend != prevProps.legend || this.props.dayStyles != prevProps.dayStyles || this.state.current != prevState.current) {
      this.setState({dayStyles: this.createDayStyles()});
    }

    const selected = Array.isArray(this.props.selected) ? this.props.selected : [this.props.selected];
    const prevSelected = Array.isArray(prevProps.selected) ? prevProps.selected : [prevProps.selected];

    if (!compareDateArrays(selected, prevSelected, 'minute') || timezoneChanged || prevProps.dayOfWeek != this.props.dayOfWeek) {
      this.setState(this.createDateState(selected, this.props.dayOfWeek));
    }
    validateValueFormat(this.props);
  }

  updateMinMax() {
    const min = this.props.min;
    const max = this.props.max;

    this.min = min == 'now' ? this.startOfToday : min ? this.parseDate(min) : null;
    this.max = max == 'now' ? this.endOfToday : max ? this.parseDate(max) : null;
    this.filteredYears = !this.min && !this.max
      ? years
      : years.filter(y => {
        const m = moment;
        const afterMin = !this.min || y.value >= this.min.year();
        const beforeMax = !this.max || y.value <= this.max.year();

        return afterMin && beforeMax;
      });
  }

  render() {
    const { type, enterToggles, selected, start, legend, onChange, dayStyles, timezone, valueFormat, width, min, max, disablePhoneInput, daysOfWeek, dayOfWeek, ...remaining} = this.props;

    this.calculateDimensions();

    const actualWidth = this.getPropsWidth(false);
    const current = this.state.current.clone();
    const calendar = <VBox data-test='Calendar' ref={this.props.legend ? undefined : this.ref} width={actualWidth || MAX_WIDTH} minWidth={actualWidth || MAX_WIDTH} maxWidth={actualWidth || MAX_WIDTH} overflow='hidden'
        bg='white' px={this.px + 'px'} py={this.py + 'px'} border='solid 1px' borderColor='border' borderRadius='form' 
        userSelect='none' tabIndex={0} onKeyDown={this.onKeyDown} outline='none' {...remaining}>
        {this.renderHeader(current)}
        {this.renderDaysOfWeek(current)}
        {this.renderDaysOfMonth(current)}
      </VBox>

    if (!this.props.legend) {
      return calendar;
    }

    return <VBox ref={this.ref} width='fit-content' gap='$12' bg='white' maxWidth='100%' borderRadius='form'>
      {calendar}
      <Legend width={actualWidth || MAX_WIDTH} items={this.props.legend.map(legendItem => {
        const {days, start, end, ...item} = legendItem;
        return item;
      })} />
    </VBox>
  }

  renderHeader(date:moment.Moment) {
    return <HBox hItemSpace='$8' pb='$20' px='6px' vAlign='center' width='100%'>
      <Icon alt='Prev' name='Left' size='medium' cursor='pointer' onClick={this.onClickPrevMonth} />
      <Dropdown data-field='Month' flex={1} options={months} value={this.state.current.get('month')} onChange={this.onMonthChange} disablePhoneInput={this.useDisablePhoneInput} />
      <Dropdown data-field='Year' flex={1} options={this.filteredYears} value={this.state.current.get('year')} onChange={this.onYearChange} disablePhoneInput={this.useDisablePhoneInput} />
      <Icon alt='Next' name='Right' size='medium' cursor='pointer' onClick={this.onClickNextMonth} />
    </HBox>
  }

  renderDaysOfWeek(date:moment.Moment) {
    return this.renderWeek(0, [{label:'Su'}, {label:'M'}, {label:'T'}, {label:'W'}, {label:'Th'}, {label:'F'}, {label:'S'}], undefined, true);
  }

  renderDaysOfMonth(date:moment.Moment) {
    const weeks = [];
    const day = date.clone();

    // set to start of the month
    day.date(1);

    const month = day.month();

    while (day.month() == month) {
      const days = this.getDaysOfWeek(day);
      weeks.push(this.renderWeek(weeks.length + 1, days, 'normal', false, day.month() != month));
    }

    return weeks;
  }

  get useDisablePhoneInput() {
    return this.props.disablePhoneInput && !getAppMode('test')
  }

  getDaysOfWeek(day:moment.Moment) {
    const month = day.month();
    const start = day.clone();
    let startDayOfWeek = day.day();
    let days:DayRenderInfo[] = [];

    do {
      const styles = this.state.dayStyles?.[day.format(DATE_STYLE_MAP_KEY_FORMAT)];

      days.push({label:day.date().toString(), date: day.clone(), styles, before: this.beforeMin(day), after: this.afterMax(day)});
      day.add(1, 'd');
    }
    while (day.day() != 0 && day.month() == month)

    // for weeks that start or end in the middle of the week
    this.addDaysFromPrevMonth(start, days, startDayOfWeek);
    this.addDaysFromNextMonth(start, days);

    return days;
  }

  beforeMin(day:moment.Moment) {
    return day && this.min && day.isBefore(this.min);
  }

  afterMax(day:moment.Moment) {
    return day && this.max && day.isAfter(this.max)
  }

  addDaysFromPrevMonth(dayInWeek:moment.Moment, days:DayRenderInfo[], startDayOfWeek:number) {
    let day = startDayOfWeek;

    while (day != 0) {
      const date = dayInWeek.clone().day(0).add(day - 1, 'days');
      const styles = this.state.dayStyles?.[date.format(DATE_STYLE_MAP_KEY_FORMAT)];
      days.unshift({label:date.date().toString(), date, styles, outside: true});
      --day;
    }
  }

  addDaysFromNextMonth(dayInWeek:moment.Moment, days:DayRenderInfo[]) {
    while (days.length != 7) {
      const date = dayInWeek.clone().day(0).add(days.length, 'days');
      const styles = this.state.dayStyles?.[date.format(DATE_STYLE_MAP_KEY_FORMAT)];
      days.push({label:date.date().toString(), date, styles, outside: true});
    }
  }

  renderWeek(key:number, days:DayRenderInfo[], fontWeight:string, first:boolean, last?:boolean) {
    return <HBox key={key} vItemSpace={last ? undefined : '$8'}>
      {days.map((day, index) => {
        const isCurrent = day.date?.isSame(this.state.current, 'd');
        const styles = this.getDayStyles(day);
        const visible = (!first && day.date != null) || this.props.daysOfWeek;
        const inactive = !visible || !this.props.onChange;
        const css = inactive ? undefined : {':hover':{bg: 'secondaryHover', color: 'primary'}, border: isCurrent ? 'dotted 1px' : undefined};
        const cursor = inactive ? undefined : 'pointer';
        const height = first ? undefined : this.boxInside;

        return <HBox data-test={day.date?.format('MM/DD/YY')} data-legend={day.styles?.name} key={index} hAlign='center' mx={this.boxInset} px={this.boxPadding} style={{...day.styles?.outer, ...styles.outer}}>
          <HBox height={height} width={this.boxInside} cursor={cursor} position='relative'
          text='subtitle2' borderRadius='standard' vAlign='center' hAlign='center' fontWeight={fontWeight} css={css}
          onClick={this.onClickDay.bind(this, day.date, day.label)} {...day.styles?.inner} {...styles.inner}>
          {day.label}
        </HBox></HBox>;
      })}
    </HBox>
  }

  getDayStyles(day:DayRenderInfo) {
    const date = day.date;
    const styles:any = {inner: {color: day.outside || day.before || day.after ? 'disabledText' : undefined}, outer: {}};

    this.addTodayStyles(styles, date);
    this.addSelectionStyles(styles, date, day.label);

    return styles;
  }

  addTodayStyles(styles:any, date:moment.Moment) {
    if (date?.isSame(moment(), 'date')) {
      styles.inner.border = 'solid 1px',
      styles.inner.borderColor = 'primary',
      styles.inner.borderRadius = 'standard'
    }
  }

  addSelectionStyles(styles:any, date:moment.Moment, label:string) {
    const selected = this.state.selected;

    const selectedDate = date && selected.find(selectedDate => compareDates(selectedDate, date));
    const selectedDayOfWeek = !date && this.props.daysOfWeek && this.state.dayOfWeek == labelToDayOfWeek[label];
    
    if (selectedDate || selectedDayOfWeek) {
      styles.inner.color = 'primaryInverse';
      styles.inner.bg = 'primary';
      styles.inner.borderRadius = 'standard';
    }

    if (date && (this.props.type == 'range' || this.props.type == 'range-start' || this.props.type == 'range-end')) {
      const start = compareDates(selected[0], date);
      const end = compareDates(selected[1], date);
      const after = selected[0] && date.isSameOrAfter(selected[0], 'date');
      const before = selected[1] && date.isSameOrBefore(selected[1], 'date');

      if (after && before) {
        styles.outer.background = createLeftRightBackgroundColor(after && !start, before && !end, createOuterBackgroundColor('primary'));
      }
    }
  }

  onKeyDown = (event:React.KeyboardEvent<HTMLElement>) => {
    // if the event is for a child of us, then its for
    // one of the dropdowns, so bail in that case
    // else the target is the calendar or being forwarded
    // to the calendar and let it come through
    if (this.element != event.target && this.element.contains(event.target as HTMLElement)) {
      return;
    }
    
    const current = this.state.current.clone();
    const next = current.clone();

    if (event.key == 'ArrowLeft') {
      next.subtract(1, 'day');
    }
    else
    if (event.key == 'ArrowUp') {
      next.subtract(1, 'week');
    }
    else
    if (event.key == 'ArrowRight') {
      next.add(1, 'day');
    }
    else
    if (event.key == 'ArrowDown') {
      next.add(1, 'week');
    }
    else if (event.key == 'Enter') {
      this.selectDay(this.state.current, true);
      event.preventDefault();
      return;
    }    
    else {
      return;
    }

    this.setCurrent(next);
    event.preventDefault();
  }

  onClickPrevMonth = () => {
    const current = this.state.current.clone();
    current.subtract(1, 'month');

    this.restrictToMinMaxMonth(current);
    this.setCurrent(current);
  }

  onMonthChange = (event:React.ChangeEvent<Dropdown>) => {
    const current = this.state.current.clone();

    current.set('month', event.currentTarget.value as number);

    this.restrictToMinMaxMonth(current);
    this.setCurrent(current);
  }

  onYearChange = (event:React.ChangeEvent<Dropdown>) => {
    const current = this.state.current.clone();

    current.set('year', event.currentTarget.value as number);
    this.setCurrent(current);
  }

  onClickNextMonth = () => {
    const current = this.state.current.clone();

    const one = 1;
    current.add(one, 'month');

    this.restrictToMinMaxMonth(current);
    this.setCurrent(current);
  }

  setCurrent(current:moment.Moment) {
    this.setState({current: this.restrictToMinMax(current)});
  }

  restrictToMinMaxMonth(current:moment.Moment) {
    if (this.min && current.isBefore(this.min, 'month')) {
      current.set('year', current.year() + 1)
    }

    if (this.max && current.isAfter(this.max, 'month')) {
      current.set('year', current.year() - 1)
    }
  }

  onClickDay(date:moment.Moment, label:string) {
    if (date) {
      this.selectDay(date);
    }
    else {
      this.selectDayOfWeek(label);
    }
  }

  selectDay(date:moment.Moment, fromKeyboard?:boolean) {
    // user has clicked on the header if date is null
    if (!date) {
      return;
    }

    if (this.beforeMin(date) || this.afterMax(date)) {
      return;
    }

    let selected:moment.Moment[];

    if (this.props.type == 'single') {
      selected = [date];
    }
    else
    if (this.props.type == 'range-start') {
      selected = [date, this.state.selected[1]];
      selected = selected.sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
    }
    else
    if (this.props.type == 'range-end') {
      selected = sortDates([this.state.selected[0], date]);
    }
    else
    if (this.props.type == 'range') {
      if (!this.state.selected.length || this.state.selected.length > 1 || this.state.selected[0] == undefined) {
        selected = [date];
      }
      else {
        selected = sortDates([date, this.state.selected[0]]);
      }
    }
    else {
      const selectedWithoutDate = this.state.selected.filter(selectedDate => !compareDates(date, selectedDate));
      if (selectedWithoutDate.length != this.state.selected.length) {
        if (fromKeyboard && !this.props.enterToggles) {
          return;
        }

        selected = selectedWithoutDate;
      }
      else {
        selected = [...selectedWithoutDate, date];
      }
    }

    this.updateDatesAndDispatch(selected);
  }

  updateDatesAndDispatch(selected:moment.Moment[], dayOfWeek?:string) {
    const state = this.createDateState(selected, dayOfWeek);

    if (this.props.onChange) {
      const value = formatDateValue(this.props, state.selected);
      dispatchChangeEvent(this, value, this.props.onChange, {value, dayOfWeek});
    }
    else 
    if (!this.controlled) {
      this.setState({selected: state.selected, dayOfWeek});
    }
  }

  selectDayOfWeek(label:string) {
    if (!this.props.daysOfWeek) {
      return;
    }

    this.updateDatesAndDispatch([], labelToDayOfWeek[label])
  }

  createDateState(dates:DateInput[], dayOfWeek?:string):State {
    const selected = dates.map(date => isValidDate(date) ? this.parseDate(date) : undefined);

    let current = this.props.type == 'single' || this.props.type == 'range-end' || this.props.type == 'multiple'
      ? selected[selected.length - 1]
      : selected[0]
    
    if (!current || !isValidDate(current)) {
      current = this.startOfToday;
    }

    return {selected:selected.map(d => this.restrictToMinMax(d)), current:this.restrictToMinMax(current), dayOfWeek};
  }

  parseDate(d:DateInput) {
    return parseSingleDate(d, this.props.timezone);
  }

  restrictToMinMax(d:moment.Moment) {
    if (!d) {
      return d;
    }

    if (this.min) {
      d = moment.max(d, this.min);
    }

    if (this.max) {
      d = moment.min(d, this.max);
    }

    return d.clone();
  }

  createDayStyles() {
    const dayStyles:DayStylesMapState = {};

    if (this.props.dayStyles) {
      for (let date in this.props.dayStyles) {
        const styles = this.props.dayStyles[date] as DayStyles;
        dayStyles[date] = styles.outer || styles.inner ? styles : {inner: styles as BoxProps};
      }
    }

    if (this.props.legend) {
      this.props.legend.forEach(item => {
        let {name, days, start, end, ...styles} = item;

        if (start && end) {
          // make the bounds just before and just after the month so we don't have large arrays
          // of days for large ranges.  use just before/after month so we don't incorrectly show
          // the range starts/ends at the begin/end of the month
          // 
          // we need to shift the current, which is utc, to the current tz, so that we can get the
          // start of the day in the current tz, which is important for the range (see T6278)
          const rangeStart = moment.max([this.currentInTz.startOf('month').startOf('d').subtract(1, 'd'), this.parseDate(start)]);
          const rangeEnd = moment.min([this.currentInTz.endOf('month').endOf('d').add(1, 'd'), this.parseDate(end)]);
          days = datesBetween(rangeStart, rangeEnd);
        }

        const sortedDays = sortDates(days);
        sortedDays.forEach((day, index) => {
          // detect if this date is a range
          const prevDay = index > 0 ? sortedDays[index - 1] : undefined;
          const nextDay = index < sortedDays.length ? sortedDays[index + 1] : undefined;
          const thisDay = day;
          const date = thisDay.format(DATE_STYLE_MAP_KEY_FORMAT);
          const left = compareDates(thisDay.clone().subtract(1, 'days'), prevDay);
          const right = compareDates(thisDay.clone().add(1, 'days'), nextDay);
          const outerBg = createLeftRightBackgroundColor(left, right, createOuterBackgroundColor(styles.bg));
          const dayStyle = dayStyles[date] as DayStyles;

          dayStyles[date] = {
            name,
            inner: !left || !right ? Object.assign({}, dayStyle?.inner, styles) : undefined, 
            outer: left || right ? Object.assign({}, dayStyle?.outer, styles, {background: outerBg}) : undefined
          };
        });
      })
    }

    return dayStyles;
  }
}

export function formatDateValue(props:Pick<CalendarProps, 'type' | 'valueFormat' | 'timezone'>, selected:DateInput[]) {
  return props.type == 'single' ? formatSingleDateValue(props, selected[0]) : selected?.map(d => formatSingleDateValue(props, d))
}

export function formatSingleDateValue(props:Pick<CalendarProps, 'valueFormat' | 'timezone'>, selected:DateInput) {
  return !moment.isMoment(selected)
    ? selected
    : props.valueFormat == DateValueFormat.date || (props.valueFormat == DateValueFormat.guess && !props.timezone)
      ? selected?.format?.(DATE_VALUE_FORMAT)
      : selected;
}

export function validateValueFormat(props:Pick<CalendarProps, 'valueFormat' | 'timezone'>) {
  if (props.valueFormat == DateValueFormat.date && props.timezone) {
    console.error('Calendar: valueFormat is set to date but timezone is set.  This will result in buggy behavior.  Either remove the timezone or set valueFormat to dateTime');
  }
}

function createOuterBackgroundColor(color:any, alpha = .30) {
  const colorComponents = colorToComponent((theme.colors as any)[color as string] || color);
  return componentToHex(blendColor(colorComponents, alpha));
}

function createLeftRightBackgroundColor(left:boolean, right:boolean, color:string) {
  return left && right
    ? color
    : left
      ? `linear-gradient(90deg, ${color} 50%, #00000000 50%)`
      : right
        ? `linear-gradient(90deg, #00000000 50%, ${color} 50%)`
        : undefined;
}

const labelToDayOfWeek:any = {
  'Su':'Sunday',
  'M': 'Monday',
  'T': 'Tuesday',
  'W': 'Wednesday',
  'Th': 'Thursday',
  'F': 'Friday',
  'S': 'Saturday'
}
