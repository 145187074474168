import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminMutationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminMutationQuery = {
  mutation?:
    | {
        id: string;
        displayName?: string | null | undefined;
        name?: string | null | undefined;
        ip?: string | null | undefined;
        lr?: string | null | undefined;
        start?: string | null | undefined;
        end?: string | null | undefined;
        variables?: { [key: string]: any } | null | undefined;
        user?: { id: string; name?: string | null | undefined } | null | undefined;
        mutationChanges?:
          | Array<{ targetId?: string | null | undefined; targetType?: string | null | undefined; targetChanges?: { [key: string]: any } | null | undefined; kind?: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminMutationDocument = gql`
  query AdminMutation($id: ID!) {
    mutation(mutation: $id) {
      id
      displayName
      name
      user {
        id
        name
      }
      ip
      lr
      start
      end
      variables
      mutationChanges {
        targetId
        targetType
        targetChanges
        kind
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminMutationArgs = Omit<UseQueryArgs<AdminMutationQueryVariables, AdminMutationQuery>, 'query'>;

export function useAdminMutationQuery(options: AdminMutationArgs = {}) {
  return useQuery<AdminMutationQueryVariables, AdminMutationQuery>({ query: AdminMutationDocument, ...options });
}

export type AdminMutationOptions = ExecQueryOptions<AdminMutationQueryVariables>;

export function adminMutation(options: AdminMutationOptions) {
  return executeQuery<AdminMutationQuery, AdminMutationQueryVariables>(AdminMutationDocument, options);
}

export type AdminMutationSelections = AdminMutationQuery['mutation'];
