import * as React from 'react'
import { useParams } from 'react-router';

import { DateField, Field, Panel, RepeatingSection } from 'app2/components';

import { CourseLink, EnrollmentLink } from '../shared';

import { useAdminStudentEnrollmentsQuery } from './generated';

export function Enrollments() {
  const { id } = useParams<{id:string}>();

  const [result] = useAdminStudentEnrollmentsQuery({variables:{id}});
  const item = result.data?.student;

  function render() {
    return <Panel title='Enrollments' initialValues={item}>
      <RepeatingSection name='enrollments' fields={[
        <Field name='id' label='ID' component={EnrollmentLink} />,
        <Field name='createdAt' label='Created' component={DateField} />,
        <Field name='course.name' label='Parent' component={CourseLink} />,
        <Field name='status' label='Status' format={(value:string) => value == "PAID" ? "Enrolled" : value} />,
      ]} />
    </Panel>
  }

  return render();
}
