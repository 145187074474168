import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PromoteEnrollmentsMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID'];
  discount?: Types.InputMaybe<Types.DiscountInput>;
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  startDate?: Types.InputMaybe<Types.Scalars['ISO8601Date']>;
}>;

export type PromoteEnrollmentsMutation = { enrollmentsPromote?: { enrollments?: Array<{ id: string } | null | undefined> | null | undefined } | null | undefined };

export const PromoteEnrollmentsDocument = gql`
  mutation PromoteEnrollments($courseId: ID!, $discount: DiscountInput, $ids: [ID!]!, $startDate: ISO8601Date) {
    enrollmentsPromote(courseId: $courseId, discount: $discount, ids: $ids, startDate: $startDate) {
      enrollments {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type PromoteEnrollmentsOptions = ExecMutationOptions<PromoteEnrollmentsMutationVariables>;

export function promoteEnrollments(options: PromoteEnrollmentsOptions) {
  return executeMutation<PromoteEnrollmentsMutation, PromoteEnrollmentsMutationVariables>(PromoteEnrollmentsDocument, options);
}
