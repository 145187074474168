import * as React from 'react';

import { DateField, Field, FieldRendererProps, Link, noOpErrorHandler, Panel, RepeatingSection, Section, TimeField, useForm } from 'app2/components';

import { UserLink } from '..';

import { renderMutationName } from './MutationChangesPanel';
import { AdminMutationSelections, useAdminMutationQuery } from './generated';

interface Props {
  id:string;
}

export function MutationPanel(props:Props) {
  const { id } = props;
  const [result] = useAdminMutationQuery({variables: {id}, error: noOpErrorHandler, debounce: {delay:2000}});
  const form = useForm(result.data?.mutation, [result.data?.mutation]);

  function render() {
    return <Panel title='Mutation' form={form}>
      <Section label='Date' name='start' whiteSpace='nowrap' component={DateField} />
      <Section label='Time' name='start' whiteSpace='nowrap' component={TimeField} />
      <Section label='User' name='user.name' whiteSpace='nowrap' component={UserLink} />
      <Section label='IP' name='ip' />
      <Section label='LogRocket' name='lr' component={Link} />
      <Section label='Mutation' name='name' whiteSpace='nowrap' render={renderMutationName} />
      <RepeatingSection name='mutationChanges' equalWidths={false} align='top' fields={[
        <Field label='ID' name='targetType' render={renderChange} />,
        <Field label='Change type' name='kind' />
      ]} />
    </Panel>
  }

  function renderChange(props:FieldRendererProps<AdminMutationSelections['mutationChanges'][0]>) {
    const change = props.info.record;
    return <Link to={`/admin2/mutation-changes/${change.targetType}/${change.targetId}#${id}`}>{change.targetType} ({change.targetId})</Link>;
  }

  return render();
}
