import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface NameAndId {
  id:string;
  name:string;
}

interface Props {
  info?:FieldInfo<NameAndId>;
  student?:NameAndId;
}

export function StudentLink(props:Props) {
  const student = props.student || props.info.record;

  return <Link className='hr-value' text='body' to={`/admin2/students/${student?.id}`}>{student?.name} ({student.id})</Link>
}

StudentLink.fieldProps = {
  fieldProperty: 'info',
}