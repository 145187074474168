import * as React from 'react';
import { useParams } from 'react-router';

import { Tabs} from 'app2/components';

import { AdminPage } from '../AdminPage';
import { MutationChangesPanel, HistoryVersions } from '../shared';

import { EnrollmentInfo } from './EnrollmentInfo';

export function Enrollment() {
  const { id } = useParams<{id:string}>();

  function render() {
    return <AdminPage title='Enrollment'>
      <Tabs baseUrl={`/admin2/enrollments/${id}/:tab`} urlParameter='tab' tabs={[
        {label:'Info', name: 'info', content: <EnrollmentInfo />}, 
        {label:'History', name:'mutations', content: <MutationChangesPanel type='Enrollment' id={id} />},      
        {label:'History (dont use)', name:'history', content: <HistoryVersions type='Enrollment' id={id} />},
      ]} />
    </AdminPage>
  }

  return render();
}
