import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminEnrollmentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminEnrollmentQuery = {
  enrollment?:
    | {
        id: string;
        status: Types.EnrollmentStatusEnum;
        rosterPeriod?: string | null | undefined;
        discountedPrice: number;
        purchased: boolean;
        startDate?: string | null | undefined;
        priceConfig?: { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | null | undefined;
        student?: { id: string; name: string } | null | undefined;
        parent?: { id: string; name?: string | null | undefined } | null | undefined;
        course: { id: string; name?: string | null | undefined };
        company: { id: string; name?: string | null | undefined };
      }
    | null
    | undefined;
};

export const AdminEnrollmentDocument = gql`
  query AdminEnrollment($id: ID!) {
    enrollment(enrollment: $id) {
      id
      status
      rosterPeriod
      discountedPrice
      purchased
      startDate
      priceConfig {
        kind
      }
      student {
        id
        name
      }
      parent {
        id
        name
      }
      course {
        id
        name
      }
      company {
        id
        name
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminEnrollmentArgs = Omit<UseQueryArgs<AdminEnrollmentQueryVariables, AdminEnrollmentQuery>, 'query'>;

export function useAdminEnrollmentQuery(options: AdminEnrollmentArgs = {}) {
  return useQuery<AdminEnrollmentQueryVariables, AdminEnrollmentQuery>({ query: AdminEnrollmentDocument, ...options });
}

export type AdminEnrollmentOptions = ExecQueryOptions<AdminEnrollmentQueryVariables>;

export function adminEnrollment(options: AdminEnrollmentOptions) {
  return executeQuery<AdminEnrollmentQuery, AdminEnrollmentQueryVariables>(AdminEnrollmentDocument, options);
}

export type AdminEnrollmentSelections = AdminEnrollmentQuery['enrollment'];
