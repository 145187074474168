import * as React from 'react';
import pluralize from 'pluralize';
import { last, omit } from 'lodash-es';
import moment from 'moment';

import { DiscountFormat, CourseUtils, EnrollmentUtils, ChangePricingPeriod, PriceConfigKind, RecurringPriceConfig } from 'app2/api';
import { Field, Form, formatShortDate, FieldInfo, FormModel, Info, TextAreaField, Modal, RadioGroup, RepeatingSection, Section, Text, useForm, useForceUpdate } from 'app2/components';
import { EnrollmentForm, formatRecurringPrice, getEnrollmentConfig, EnrollmentConfigurationForm, } from 'app2/views/shared-public';
import { StudentNameField, ScheduleField, errorPathTransformHandler } from 'app2/views/shared';

import { CourseSelections } from '../../../generated';
import { DistinctEnrollmentsSelections } from '../../enrolled';
import { DiscountComboAndBreakdown, useEnrollmentBreakdown } from '../price-config';

import { ChangeRecurringsMutationVariables, changeRecurrings } from './generated';

interface EditForm extends EnrollmentForm {
  effective: ChangePricingPeriod;
  format?: DiscountFormat;
  rate?: number;
  enrollments:DistinctEnrollmentsSelections[];
  description?:string;
}

interface Props {
  course: CourseSelections;
  enrollments: DistinctEnrollmentsSelections[];
}

export function ChangeSubscriptions(props: Props) {
  const {course, enrollments} = props;

  const protoEnrollment = enrollments[0];
  const protoConfig = EnrollmentUtils.findPriceConfig(protoEnrollment, config => config.kind == PriceConfigKind.Recurring) as RecurringPriceConfig;

  const discount = last(protoEnrollment?.discountUses) || {rate:null, format: null};
  const form = useForm<EditForm>({ id: props.course?.id, recurring: {...protoConfig, startDate: null}, effective: ChangePricingPeriod.Next, ...discount, description:protoConfig?.updateDescription, enrollments }, [course], {alwaysSave: true});
  const config = getEnrollmentConfig(form);
  const futureEnrollment = moment(protoEnrollment.startDate).isAfter(moment(), 'd');
  const purchased = moment(protoEnrollment?.billingStartDate).isSameOrBefore(moment(), 'd');
  const effectiveNow = form.values.effective == ChangePricingPeriod.Current;
  const showStartDatePicker = futureEnrollment && effectiveNow;
  const { first, ongoing } = useEnrollmentBreakdown(course.id, null, 0, config, protoEnrollment, form.values.effective);
 
  const currentConfig = config?.recurring || EnrollmentUtils.usingRecurring(protoEnrollment);
  const periods = CourseUtils.getPeriods(props.course, currentConfig.unit);
  const courseEnding = !periods.next?.start;

  const forceUpdate = useForceUpdate();
  checkForDefaultConfig();

  function render() {
    return <Modal title="Change subscriptions">
      <Form width="100%" form={form} onOk={handleSubmit} editing onNavigation="nothing" onChange={onFormChange}>
        <RepeatingSection name="enrollments" bordered fields={[
          <Field label="Student" name="student.name" readOnly nameKind='full' component={StudentNameField}  />,
          <Field label="Subscription" name="priceConfigs" readOnly render={props => renderSubscription(props.info.record)}  />,
          <Field label="Schedule" name="rosterPeriods" readOnly component={ScheduleField} />
        ]} />
        <EnrollmentConfigurationForm course={props.course} form={form} recurring={{startDate: showStartDatePicker}} kind={PriceConfigKind.Recurring} />
        {purchased && !courseEnding && 
          <Section label='Effective date' name='effective' required component={RadioGroup} validators={validateEffectivePeriodAndUnit} 
            options={[{value: ChangePricingPeriod.Current, label: 'Today'}, {value: ChangePricingPeriod.Next, label: `Next period (${formatShortDate(periods.next?.start)})`}]} />}
          <DiscountComboAndBreakdown parentCourse={props.course} course={props.course} config={config} changingEnrollment={protoEnrollment} effective={form.values.effective} />
        <Section label="Reason for the change" name='description' autoSize minHeight='65px' component={TextAreaField} />
        {renderWarnings()}
      </Form>
    </Modal>
  }

  function renderSubscription(e:DistinctEnrollmentsSelections) {
    const config = e.priceConfigs.find(p => p.kind == PriceConfigKind.Recurring) as RecurringPriceConfig;
    const price = config ? CourseUtils.findRecurringPriceForConfig(props.course.prices, config) : null;

    return price
      ? formatRecurringPrice(price)
      : <Text color='red'>No subscription</Text>
  }

  function renderWarnings() {
    const date = (first || ongoing)?.date
    if (!date) return null;

    return <Info type='warning' message={`${pluralize('Student', enrollments.length)} will be enrolled in new plan as of ${formatShortDate(date)}.`} />
  }

  // EnrollmentConfigurationForm creates a default config, but after we are rendered sometimes
  // so we need to check for it after we render and potentially force update
  function checkForDefaultConfig() {
    React.useEffect(() => {
      if (!config && getEnrollmentConfig(form)) {
        forceUpdate()
      }
    });
  }

  function onFormChange(form: FormModel<EditForm>) {
    const effective = form.values.effective;

    if (effective == ChangePricingPeriod.Next && (!purchased || !periods.next?.start)) {
      form.setValue('effective', ChangePricingPeriod.Current);
    }
  }

  const validateEffectivePeriodAndUnit = React.useMemo(() => function (value:ChangePricingPeriod, info:FieldInfo<EditForm, 'effective'>) {
    return config && value == ChangePricingPeriod.Next && protoConfig.unit != config.recurring.unit
      ? 'You can not change the billing period duration for scheduled changes'
      : null;
    }, [config?.recurring?.unit]);

  async function handleSubmit(form: FormModel<EditForm>) {
    const values = form.values;
    const variables: ChangeRecurringsMutationVariables = {
      courseId: props.course.id,
      ids: values.enrollments.map(e => e.id),
      kind: config.kind,
      // for currently active enrollments, startDate in the form is used internally
      // for fetching the breakdown, and effective dictates when the change occurs
      // so we need to omit start date in that case
      recurring: futureEnrollment ? config.recurring : omit(config.recurring, 'startDate'),
      effective: values.effective,
      description: values.description,
      discount: { format: values.format, rate: values.rate }
    };
    const successMsg = `${pluralize('Subscription', values.enrollments.length, true)} changed`;
    const [success] = await changeRecurrings({ variables, successMsg, error: errorPathTransformHandler(form, 'recurringPriceConfig', 'recurring') });
    
    return success;
  }

  return render();
}
