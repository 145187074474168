import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import pluralize from 'pluralize';
import * as qs from 'query-string';

import { observer } from 'app/observable';
import { eventManager } from 'app2/api';

import { MarketAreaStore } from 'app2/api/public';
import {
  HBox,
  Heading1,
  Subtitle2,
  withBreakpoints,
  BreakpointProps,
  buildBreadCrumbStr,
  LoadMore,
  Loader
} from 'app2/components';

import { PublicPage } from '../../shared-public';

import { CatalogCard } from './CatalogCard';

const pageSize = 99;

interface Props
  extends BreakpointProps,
    RouteComponentProps<{ slug: string }> {}

@observer
export class InnerMarketAreaCatalogs extends React.Component<Props> {
  store: MarketAreaStore = new MarketAreaStore();

  get url() {
    return `/in/${this.store.marketArea.slug}/catalog`;
  }

  get page() {
    return Math.max(qs.parse(this.props.location.search).page || 0, 0);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(_: Props) {
    this.loadData();
  }

  async loadData() {
    try {
      const slug = this.props.match.params.slug;
      const promises = this.store.load(slug);

      // we only load the first set of catalogs if the
      // slug has changed, else the LoadMore component
      // handles loading each catalog page
      if (!promises.length) {
        return;
      }

      promises.push(this.store.loadCatalogPage(pageSize, this.page));

      await Promise.all(promises);

      this.logPageView();
    } catch (_) {}
  }

  logPageView() {
    eventManager.log('viewCityCatalogPage', {
      url: window.location.href,
      breadCrumbs: buildBreadCrumbStr(this.crumbs),
      totalNumberOfCatalogListings: this.store.catalogs.pagination.count,
      totalNumberOfProviders: this.store.catalogs.meta.provider_count
    });
  }

  get crumbs() {
    if (!this.store.marketArea) {
      return [];
    }

    return [
      {
        label: this.store.marketArea.name,
        url: `/in/${this.store.marketArea.slug}`
      },
      {
        label: 'Activities Catalog',
        url: this.url
      }
    ];
  }

  render() {
    if (!this.store.marketArea || !this.store.catalogs) {
      return <Loader />;
    }

    const catalogs = this.store.catalogs;
    const numPerRow = [1, 2, 3][this.props.breakpoint];

    const title = `${this.store.marketArea.name}: Browse Local Activities for Kids`;
    const description = `Discover local kids activities covering a wide range of subjects including, art, music, cooking, stem and coding near ${this.store.marketArea.name}.`;

    return <PublicPage head={{title, description}} crumbs={this.crumbs} useMaxWidth header footer>
      <HBox mb="$40" width="100%" vAlign="center">
        <Heading1>Activities in your region</Heading1>
        <HBox flex="1" />
        <Subtitle2 mr="$15">
          {catalogs.pagination.count}{' '}
          {pluralize('activity', catalogs.pagination.count)}
        </Subtitle2>
      </HBox>
      <LoadMore
        width="100%"
        numPages={catalogs.pagination.pages}
        numPerRow={numPerRow}
        load={this.loadCatalogs}
        component={CatalogCard}
        dataKey={this.store.slug}
      />
    </PublicPage>
  }

  loadCatalogs = async (page: number) => {
    const promise = this.store.loadCatalogPage(pageSize, page);

    if (promise) {
      await promise;
    }

    return this.store.catalogs.records;
  };
}

export const MarketAreaCatalogs = withBreakpoints(
  withRouter(InnerMarketAreaCatalogs),
  ['635px', '1040px']
);
