import * as React from 'react';

import { Button, Collapsible, copyToClipboard, DateField, Field, FieldRendererProps, FieldValue, HBox, Icon, Link, noOpErrorHandler, Panel, RepeatingSection, Table, TimeField, useForm, VBox } from 'app2/components';

import { MutationLink, UserLink } from '..';

import { allowedTypes } from './HistoryVersions';
import { AdminMutationChangesSelections, useAdminMutationChangesQuery } from './generated';

interface Props {
  type:string;
  id:string;
}

export function MutationChangesPanel(props:Props) {
  const { type:propsType, id } = props;
  const type = allowedTypes.indexOf(propsType) != -1 ? propsType : undefined;
  const [result] = useAdminMutationChangesQuery({variables: {type, id}, error: noOpErrorHandler, debounce: {delay:2000}});
  const form = useForm({versions: result.data?.mutationChanges}, [result.data?.mutationChanges]);

  function render() {
    return <Panel title='Versions' form={form}>
      <RepeatingSection name='versions' equalWidths={false} align='top' fields={[
        <Field label='ID' name='versionId' component={MutationLink} />,
        <Field label='Date' name='start' whiteSpace='nowrap' component={DateField} />,
        <Field label='Time' name='start' whiteSpace='nowrap' component={TimeField} />,
        <Field label='Mutation' name='displayName' whiteSpace='nowrap' render={renderMutationName} />,
        <Field label='User' name='user.name' whiteSpace='nowrap' render={renderUser} />,
        <Field label='Change type' name='kind' />,
        <Field label='changes' name='targetChanges' format={renderChanges} />,
      ]} />
    </Panel>
  }

  function renderChanges(variables:any) {
    const showBefore = false;
    const rows = [];

    for (const key in variables) {
      const row = [];
      row.push(<div style={{whiteSpace:'nowrap'}}>{key}</div>);

      const changes = variables[key];

      if (showBefore) {
        row.push(JSON.stringify(changes[0]));
      }

      row.push(JSON.stringify(changes[1]));

      rows.push(row);
    }

    return <Collapsible minHeight='100px'><Table width='100%' rows={rows} css={showBefore ? tableCssBoth : tableCss} /></Collapsible>;
  }

  function renderUser(renderProps:FieldRendererProps<AdminMutationChangesSelections>) {
    const record = renderProps.info.values[2];
    const hasTracking = Boolean(record.ip || record.lr);
    const info = hasTracking
      ? <Icon name='Info' tooltip={<VBox>
        <HBox gap='$8'>IP: {record.ip}</HBox>
        <HBox gap='$8'>LR: <Link to={record.lr}>{record.lr}</Link></HBox>
      </VBox>} />
      : null;

    return <HBox gap='$4'><UserLink info={renderProps.info as any} />{info}</HBox>;
  }

  return render();
}

const tableCss:any = {
  td: {
    padding: '4px',
    verticalAlign: 'top',
    tableLayout: 'fixed'
  },
  'td:nth-child(1)':{
    width: '140px',
    minWidth: '140px',
  },
  'td:nth-child(2), td:nth-child(3)':{
    width: '100%',
  }
}

const tableCssBoth:any = {
  ...tableCss,
  'td:nth-child(2), td:nth-child(3)':{
    width: '50%',
  }
}

export function renderMutationName(renderProps:FieldRendererProps<AdminMutationChangesSelections>) {
  const change = renderProps.info.record;
  const variables = JSON.stringify(change.variables, null, 2);
  const copy = <Button small onClick={() => copyToClipboard(variables)}>Copy</Button>
  const tooltip = <Panel title={change.name} primaryActions={copy} m='-12px'>
    <HBox whiteSpace='pre' maxHeight='350px' overflow='scroll'>{variables}</HBox>
  </Panel>;

  const mutationLink = <Link icon='Link' to={`/admin2/mutations/${change.mutationId}`} />;

  return <HBox gap='$8'>{mutationLink}<FieldValue underline borderBottom='dashed' cursor='pointer' tooltip={tooltip}>{renderProps.children}</FieldValue></HBox>; 
}
