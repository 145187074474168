import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StudentEnrollmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type StudentEnrollmentsQuery = {
  student?:
    | {
        id: string;
        enrollments?:
          | Array<
              | {
                  id: string;
                  status: Types.EnrollmentStatusEnum;
                  waitlistKind: Types.WaitlistKind;
                  pickup?: string | null | undefined;
                  dismissal?: string | null | undefined;
                  isChildEnrollment: boolean;
                  pickupDropoffRequired: boolean;
                  course: {
                    id: string;
                    disambiguatedName?: string | null | undefined;
                    status: Types.CourseStatus;
                    season: { id: string; name: string; site: { slug: string; usingPickups: boolean; pickupDestinations?: Array<string> | null | undefined; checkoutDestinations?: Array<string> | null | undefined } };
                  };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const StudentEnrollmentsDocument = gql`
  query StudentEnrollments($id: ID!) {
    student(student: $id) {
      id
      enrollments(queries: [["CONFIRMED", "WAITLISTED"], "SCHEDULED", "STUDENT_UNIQUE"], sorts: [{ name: "endDate", direction: DESC }]) {
        id
        course {
          id
          disambiguatedName
          status
          season {
            id
            name
            site {
              slug
              usingPickups
              pickupDestinations
              checkoutDestinations
            }
          }
        }
        status
        waitlistKind
        pickup
        dismissal
        isChildEnrollment
        pickupDropoffRequired
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type StudentEnrollmentsArgs = Omit<UseQueryArgs<StudentEnrollmentsQueryVariables, StudentEnrollmentsQuery>, 'query'>;

export function useStudentEnrollmentsQuery(options: StudentEnrollmentsArgs = {}) {
  return useQuery<StudentEnrollmentsQueryVariables, StudentEnrollmentsQuery>({ query: StudentEnrollmentsDocument, ...options });
}

export type StudentEnrollmentsOptions = ExecQueryOptions<StudentEnrollmentsQueryVariables>;

export function studentEnrollments(options: StudentEnrollmentsOptions) {
  return executeQuery<StudentEnrollmentsQuery, StudentEnrollmentsQueryVariables>(StudentEnrollmentsDocument, options);
}

export type StudentEnrollmentsSelections = StudentEnrollmentsQuery['student'];
