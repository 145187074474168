import * as React from 'react';
import { useParams } from 'react-router';

import { Field, FormModel, InputField, Panel, Part, RepeatingSection, Section, Tabs } from 'app2/components';

import { AdminPage } from '../AdminPage';
import { MutationChangesPanel } from '../shared';

import { SeasonInfo } from './SeasonInfo';
import { SeasonCourses } from './SeasonCourses';

export function Season() {
  const { season } = useParams<{season:string}>();

  function render() {
    return <AdminPage title='Season'>
      <Tabs baseUrl={`/admin2/seasons/${season}/:tab`} urlParameter='tab' tabs={[
      {label:'Information', name: 'info', content: <SeasonInfo />}, 
      {label:'Activities', name:'activities', content: <SeasonCourses />},
      {label:'History', name:'history', content: <MutationChangesPanel type='Season' id={season} />},
    ]} />
    </AdminPage>
  }

  return render();
}
