import React from 'react';

import { Dropdown, defaultFieldProps, Modal, SaveableResult } from 'app2/components';
import { findQueryOption, OptionDataItem } from 'app2/views/shared-public';

import { PickerProps, PickerEntityProps, getPickerEntityId } from '../PickerProps';

import { CourseFrameworkModal } from './CourseFrameworkModal';
import { useVendorCourseFrameworksQuery, useCompanyCourseFrameworksQuery, vendorCourseFrameworks, companyCourseFrameworks, VendorCourseFrameworksSelections, CompanyCourseFrameworksSelections } from './generated';

export interface CourseFrameworkPickerProps extends PickerProps {
  autoShowModal?:boolean;
}

export function CourseFrameworkPicker(props:CourseFrameworkPickerProps) {  
  const {field, course:propsCourse, vendor:vendorProp, company:companyProp, autoShowModal, ...remaining} = props;
  const {company, companyId, vendorId} = getPickerEntityId(props);

  const options = getOptions();

  const {showingModal, autoShowed } = checkForAutoShowModal();

  function render() {
    return <Dropdown options={options} onAdd={onAdd} {...remaining} />
  }

  function getOptions() {
    const [result1] = useCompanyCourseFrameworksQuery({variables: {company:companyId}, requestPolicy: 'cache-first', hideLoader: true, pause: !companyId})
    const [result2] = useVendorCourseFrameworksQuery({variables: {vendor:vendorId}, requestPolicy: 'cache-first', hideLoader: true, pause: !!companyId || !vendorId})
    const companyData = result1.data?.company || result2.data?.vendor?.company;
    return React.useMemo(() => companyData?.courseFrameworks?.map?.(createOptions), [companyData]);
  }

  function createOptions(t:VendorCourseFrameworksSelections['company']['courseFrameworks'][0] | CompanyCourseFrameworksSelections['courseFrameworks'][0]) {
    return {value: t, label: t.name};
  }

  async function onAdd(name:string) {
    if (!company?.userCompanyRole || showingModal.current) {
      return;
    }

    showingModal.current = true;
  
    const result = await Modal.show(<CourseFrameworkModal company={companyId} name={name} />)

    showingModal.current = false;

    if (result.action == SaveableResult.cancel) {
      return;
    }

    const framework = result.result;
    return framework;
  }

  // if autoShowModal the modal will show if
  // - the user has permissions to create templates
  // - there are no templates yet

  function checkForAutoShowModal() {
    const showingModal = React.useRef(false);
    const autoShowed = React.useRef(false);
    
    React.useEffect(possiblyAutoShowTemplateModal, [autoShowModal, company, options]);

    return {showingModal, autoShowed}
  }

  function possiblyAutoShowTemplateModal() {
    if (!autoShowModal || !company?.userCompanyRole || autoShowed.current || !options || options.length) {
      return;
    }

    autoShowed.current = true
    autoShowTemplateModal();
  }

  async function autoShowTemplateModal() {
    const result = await onAdd('');

    if (!result) {
      return;
    }

    props.onChange(result);
  }

  return render();
}

export async function findCourseFramework(nameOrObArray:string | OptionDataItem | string[] | OptionDataItem[], entity:PickerEntityProps):Promise<CompanyCourseFrameworksSelections['courseFrameworks'][0]> {
  const {companyId, vendorId} = getPickerEntityId(entity);

  if (!companyId && !vendorId) {
    return;
  }
  
  return companyId
    ? findQueryOption(companyCourseFrameworks, {company:companyId}, nameOrObArray)
    : findQueryOption(vendorCourseFrameworks, {vendor:vendorId}, nameOrObArray);
}

CourseFrameworkPicker.findFramework = findCourseFramework;

CourseFrameworkPicker.fieldProps = {
  ...defaultFieldProps,
  fieldProperty: 'field'
}

