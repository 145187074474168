import * as React from 'react'

import { CourseUtils, EnrollmentUtils } from 'app2/api';
import { Dropdown, Field, FieldRendererProps, Form, RepeatingSection } from 'app2/components';
import { EnrollmentActivityLink, SeasonLink } from 'app2/views/shared';
import { CourseStatusTag } from 'app2/views/shared-public';

import { useStudentEnrollmentsQuery, updateEnrollment } from './gql'

interface Props {
  id:string;
}

export function StudentEnrollments(props:Props) {
  const [result] = useStudentEnrollmentsQuery({variables: {id: props.id}});
  const student = result?.data?.student;
  const usingPickups = React.useMemo(() => student?.enrollments?.find(EnrollmentUtils.usingPickups) != null, [student]);
  const usingDismissals = React.useMemo(() => student?.enrollments?.find(EnrollmentUtils.usingDismissals) != null, [student]);

  function render() {
    return <Form initialValues={student} editing={false}>
        <RepeatingSection name='enrollments' equalWidths={false}
        fields={[
          <Field label="Season" name="course.season.name" component={SeasonLink} />, 
          <Field label='Status' name="course.status" component={CourseStatusTag} small />, 
          <Field label="Activity name" name="course" component={EnrollmentActivityLink} badge />, 
          usingPickups && <Field label="Pickup" name="pickup" render={renderPickups} />, 
          usingDismissals && <Field label="Dismissal" name="dismissal" render={renderDismissals} />,
        ]}>
      </RepeatingSection>
    </Form>
  }

  function renderPickups(render:FieldRendererProps) {
    return CourseUtils.isEnrollmentUpdateable(render.info?.record?.course) && render.info?.record?.course?.season?.site?.usingPickups && render.info?.record?.pickupDropoffRequired
      ? <Dropdown tooltips options={render.info.record.course.season.site.pickupDestinations} value={render.info.value} onChange={event => onChangePickup(render.info?.record.id, event.target.value)} />
      : render.info.value;
  }
  
  function renderDismissals(render:FieldRendererProps) {
    return CourseUtils.isEnrollmentUpdateable(render.info?.record?.course) && render.info?.record?.pickupDropoffRequired
      ? <Dropdown tooltips options={render.info.record.course.season.site.checkoutDestinations} value={render.info.value} onChange={event => onChangeDismissal(render.info?.record.id, event.target.value)} />
      : render.info.value;
  }

  async function onChangePickup(enrollment:string, value:string) {
    await updateEnrollment({variables: {enrollments:[{id: enrollment, pickup: value}]}});
  }

  async function onChangeDismissal(enrollment:string, value:string) {
    await updateEnrollment({variables: {enrollments:[{id: enrollment, dismissal: value}]}});
  }

  return render();
}