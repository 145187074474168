import * as React from 'react';
import { useParams } from 'react-router';

import { FilterOperator } from 'app2/api';
import { MenuItem, OptionsMenu, Tabs } from 'app2/components';

import { AdminPage } from '../AdminPage';
import { AdminCompanyRouteParams } from '../adminRoutes';
import { ClearStripeTokenModal, createAdjustmentHelper, CopyStripeTokenModal, MutationChangesPanel, HistoryVersions, ReviewsTable } from '../shared';

import { MergeCompanies } from './MergeCompanies';
import { Vendors } from './Vendors';
import { Team } from './Team';
import { CompanyInfo } from './CompanyInfo';
import { Courses } from './Courses';
import { Payouts } from './Payouts';
import { adminCompanyCreateAdjustment, companyClearStripeToken, useSingleAdminCompanyQuery, SingleAdminCompanySelections } from './generated';

export function Company() {
  const { id } = useParams<AdminCompanyRouteParams>();
  const [result] = useSingleAdminCompanyQuery({ variables: { id } });
  const company = result.data?.company || ({} as SingleAdminCompanySelections);

  function render() {
    return <AdminPage title="Provider" actions={renderActions()}>
      <Tabs baseUrl={`/admin2/providers/${id}/:tab`} urlParameter="tab" tabs={[
          { label: 'Information', name: 'info', content: <CompanyInfo /> },
          { label: 'Schools', name: 'schools', content: <Vendors /> },
          { label: 'Activities', name: 'activities', content: <Courses /> },
          { label: 'Team', name: 'team', content: <Team /> },
          { label: 'Payouts', name: 'payouts', content: <Payouts /> },
          { label: 'Reviews', name:'reviews', content: <ReviewsTable filter={{name:'company.id', op:FilterOperator.Eq, value:id}} />},
          { label: 'History', name:'mutations', content: <MutationChangesPanel type='Company' id={id} />},
          { label: 'History (dont use)', name:'history', content: <HistoryVersions type='Company' id={id} />},
        ]}
      />
    </AdminPage>
  }

  function renderActions() {
    return <OptionsMenu>
      <MenuItem label="Merge companies..." onClick={<MergeCompanies company={company} />} />
      <MenuItem label="Copy Stripe account..." onClick={<CopyStripeTokenModal destination={company} destinationType='Company' />} />
      <MenuItem label="Clear Stripe account..." onClick={<ClearStripeTokenModal entity={company} mutation={companyClearStripeToken} />} />
      <MenuItem label="Create adjustment..." onClick={createAdjustment} />
    </OptionsMenu>
  }

  async function createAdjustment() {
    return createAdjustmentHelper(adminCompanyCreateAdjustment, {company:id});
  }

  return render();
}
