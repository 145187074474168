import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminStudentEnrollmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminStudentEnrollmentsQuery = {
  student?:
    | { id: string; enrollments?: Array<{ id: string; createdAt: string; status: Types.EnrollmentStatusEnum; course: { id: string; name?: string | null | undefined } } | null | undefined> | null | undefined }
    | null
    | undefined;
};

export const AdminStudentEnrollmentsDocument = gql`
  query AdminStudentEnrollments($id: ID!) {
    student(student: $id) {
      id
      enrollments(sorts: [{ name: "createdAt", direction: DESC }]) {
        id
        createdAt
        status
        course {
          id
          name
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminStudentEnrollmentsArgs = Omit<UseQueryArgs<AdminStudentEnrollmentsQueryVariables, AdminStudentEnrollmentsQuery>, 'query'>;

export function useAdminStudentEnrollmentsQuery(options: AdminStudentEnrollmentsArgs = {}) {
  return useQuery<AdminStudentEnrollmentsQueryVariables, AdminStudentEnrollmentsQuery>({ query: AdminStudentEnrollmentsDocument, ...options });
}

export type AdminStudentEnrollmentsOptions = ExecQueryOptions<AdminStudentEnrollmentsQueryVariables>;

export function adminStudentEnrollments(options: AdminStudentEnrollmentsOptions) {
  return executeQuery<AdminStudentEnrollmentsQuery, AdminStudentEnrollmentsQueryVariables>(AdminStudentEnrollmentsDocument, options);
}

export type AdminStudentEnrollmentsSelections = AdminStudentEnrollmentsQuery['student'];
