import * as React from 'react';

import { CourseUtils } from 'app2/api';
import { Button, Panel, Form, InputField, Section, TextAreaField, Part, VBox, Subtitle2, Collapsible, Tabs, ButtonStripButton, useStateWithDeps, Checkbox } from 'app2/components';
import { CourseImageField, CourseCardImageField, CourseRecurringRates, ConfigurableSeasonRates, FeeBreakdown, SchoolFeeCombo, courseKindBehavior, useCourseFields, useCourseWithBreakdowns } from 'app2/views';

import { SaveAndTransitionModal } from '../../course';

import { ActivityForm } from '../ActivityForm';
import { PaymentSchedule } from './PaymentSchedule';

interface Props {
  editing: boolean;
  form: ActivityForm;
  formUi?:React.RefObject<Form>;
  onToggleEditing: (editing: boolean) => void;
  onOk: (form: ActivityForm, buttonNo?:number) => Promise<boolean>;
}

export function ActivityDetails(props: Props) {
  const form = props.form;
  const course = form?.values;
  const activityFields = useCourseFields({site: form?.initialValues?.site?.id, course})
  const common = activityFields.fields;
  form.onReset = activityFields.onFormReset as any;

  const siteRole = course?.site?.userSiteRole;
  const siteId = form.values?.site?.id;
  const companyId = form.values?.vendor?.company?.id;

  const behavior = courseKindBehavior[course?.kind];

  const rateVisibilityState = CourseUtils.getRateOrPriceState(form?.values?.rates);
  const [rateType, setRateType] = useStateWithDeps(rateVisibilityState.notConfigured ? behavior.pricingDefault : rateVisibilityState.type, [course]);

  const {breakdowns} = useCourseWithBreakdowns(course);

  function render() {
    return <Panel icon="Clipboard" title="Activity details" type={props.editing ? 'edit-toggle' : "toggle"} formUi={props.formUi} form={form} buttons={renderButtons()} onOk={props.onOk} onToggleEditing={props.onToggleEditing}>
      {!props.editing ? renderDisplay() : renderEdit()}
    </Panel>    
  }

  function renderButtons() {
    const buttons = [<Button kind='secondary'>Cancel</Button>, <Button autoLoader>Save changes</Button>];
    const transitionLabel = SaveAndTransitionModal.title(course);

    if (transitionLabel) {
      buttons.push(<Button autoLoader>{transitionLabel}</Button>);
    }

    return buttons;
  }

  function renderDisplay() {
    const fields = renderFields();

    return <>
      <Section>
        {fields.name}
        {fields.vendor || fields.site}
      </Section>
      <Section>
        {fields.location}
        {fields.room}
      </Section>
      {behavior.sessionType == 'dates' ? fields.courseDates : undefined}
      <Section>
        {fields.courseDays}
        {fields.kind}
      </Section>
      <Section>
        {fields.grades}
        <Section label="Scholarship code" name="scholarshipCode" readOnly />
      </Section>
      {fields.age}
      <Section>
        {fields.prices}
        {fields.classesCount}
      </Section>
      {fields.capacity}
      <Collapsible open={props.editing ? true : undefined} controls={!props.editing}>
        <Section>
          {fields.courseFramework}
          {fields.courseTags}
        </Section>
        {fields.description}
        {fields.supplies}
        {fields.courseCardImage}
        {fields.courseImage}
        {fields.enrollmentOpens}
        {fields.enrollmentCloses}
        {behavior.sessionType == 'days' ? fields.courseDates : undefined}
        {fields.noEnrichmentDays}
        {fields.rates}
        {behavior?.fields?.pricing?.season?.installments && <PaymentSchedule editing={props.editing} form={form} />}
        {fields.schoolFee}
        {fields.hideable}
      </Collapsible>
    </>
  }

  function renderEdit() {
    const fields = renderFields();
    return <>
      <Section>
        {fields.vendor || fields.site}
        {fields.courseFramework}
      </Section>
      <Section>
        {fields.name}
        <Section {...common.teacher} disabled={false} readOnly={common.teacher.disabled} />
      </Section>
      <Section>
        {fields.courseTags}
        {fields.grades}
      </Section>
      {fields.age}
      {fields.description}
      <Section>
        {fields.location}
        {fields.room}
      </Section>
      {behavior.sessionType == 'dates' ? fields.courseDates : undefined}
      <Section>
        {fields.courseDays}
        {fields.kind}
      </Section>
      {behavior.sessionType == 'days' ? fields.courseDates : undefined}
      <Section>
        {fields.noEnrichmentDays}
        {fields.classesCount}
      </Section>
      {fields.enrollmentOpens}
      {fields.enrollmentCloses}
      {fields.courseCardImage}
      {fields.courseImage}
      {fields.supplies}
      {fields.capacity}
      {fields.rates}
      {behavior?.fields?.pricing?.season?.installments && <PaymentSchedule editing={props.editing} form={form} />}
      {fields.schoolFee}
      {fields.breakdown}
      {fields.hideable}
    </>
  }

  function renderFields() {
    const basicSeasonHomeroomFeeIncrementField = <Part {...common['rates.season.homeroomFeeRoundingIncrementCents']} />;
    return {
      name: <Section {...common.name} edit={InputField} displayRequired />,
      vendor: siteRole ? <Section {...common.vendor} displayRequired disabled={false} readOnly={common.vendor.disabled} /> : undefined,
      site: !siteRole ? <Section {...common.site} displayRequired disabled={false} readOnly={common.site.disabled} /> : undefined,
      grades: <Section {...common.grades} />,
      age: <Section label='Ages' hidden={common.ageMin.hidden}>
        <Part {...common.ageMin} />
        <Part {...common.ageMax} />
      </Section>,
      courseDays: <Section {...common.courseDays} displayRequired />,
      kind: <Section {...common.kind} />,
      capacity: <>
        <Section label="Capacity" pair>
          <Part {...common.minCapacity} displayRequired />
          <Part {...common.maxCapacity} displayRequired />
        </Section>
        <Section continued>
          <Part {...common.teamMaxCapacity} displayRequired />
        </Section>
      </>,
      courseFramework: <Section {...common.courseFramework} displayRequired disabled={false} readOnly={common.courseFramework.disabled} />,
      courseTags: <Section {...common.courseTags} />,
      description: <Section {...common.description} edit={TextAreaField} />,
      supplies: <Section {...common.supplies} />,
      location: <Section label='School' name='site.name' readOnly />,
      room: <Section {...common.room} edit={InputField} />,
      courseCardImage: <CourseCardImageField siteId={siteId} companyId={companyId} {...common.courseCardImage} />,
      courseImage: <CourseImageField siteId={siteId} companyId={companyId} {...common.courseImage} />,
      enrollmentOpens: <Section label="Enrollment starts" displayRequired pair hidden={common.enrollmentOpens.date.hidden}>
        <Part {...common.enrollmentOpens.date} />
        <Part {...common.enrollmentOpens.time} />
      </Section>,
      enrollmentCloses: <Section label="Enrollment ends" displayRequired pair hidden={common.enrollmentOpens.date.hidden}>
        <Part {...common.enrollmentCloses.date} />
        <Part {...common.enrollmentCloses.time} />
      </Section>,
      courseDates:<Section label="Activity dates" displayRequired pair>
        <Part {...common.startDate} />
        <Part {...common.endDate} />
      </Section>,
      noEnrichmentDays: <Section {...common.noEnrichmentDays} />,
      classesCount: <Section {...common.classesCount} />,
      prices: <Section {...common.prices} mode='display' />,
      rates: <Section label='Activity pricing' layout='vbox' hidden={behavior?.fields?.pricing?.none}>
        <Part {...common.rates} component={Subtitle2} />
        <Tabs component={ButtonStripButton} width='100%' borderBottom='none' hItemSpace={0} selected={rateType} disabled={!props.editing} onChange={onChangeRateType} tabs={[{
          label: 'Basic',
          content: <Section>
            <Part {...common['rates.season.rate']} infoTip='Families purchase the entire activity in full, up front. Students are automatically enrolled in all activity dates.' displayRequired />
            <Part {...common['rates.season.materialsRate']} />
            {basicSeasonHomeroomFeeIncrementField}
          </Section>
        }, !(common['rates.seasons'].hidden && common['rates.recurring'].hidden && common['rates.dropIn.rate'].hidden) && {
          label: 'Advanced',
          content: <Part>
            <Part label='Monthly or weekly subscription' checkbox checked={rateVisibilityState.enabled.recurring} infoTip='Students are enrolled on an ongoing basis until they cancel or the activity ends.' onCheck={onClickSubscription} hidden={common['rates.recurring.rate'].hidden}>
              <CourseRecurringRates recurringFields={common} />
              <Checkbox
                label="Fixed start date"
                infoTip="When checked, subscriptions start on the day of enrollment or the activity start date, whichever comes later. Families won't be able to choose a different start date."
                checked={form?.values?.rates?.recurring?.[0]?.startDateDisabled}
                disabled={!props.editing}
                hidden={common['rates.recurring.rate'].hidden || !form?.values?.rates?.recurring?.length}
                onClick={onToggleStartDateDisabled}
              />
            </Part>
            <Part label='Daily drop-in (register/pay in advance for individual days)' checkbox checked={rateVisibilityState.enabled.dropIns} placeholder='Enter daily drop-in price' infoTip='Families enroll and pay in advance for individual days.' onCheck={onClickDropIn} hidden={common['rates.dropIn.rate'].hidden}>
              {/* Section splits the fields horizontally */}
              <Section>
                <Part {...common['rates.dropIn.rate']} />
                <Part {...common['rates.dropIn.homeroomFeeRoundingIncrementCents']} />
              </Section>
            </Part>
            <Part label="Full season" checkbox checked={rateVisibilityState.enabled.season} infoTip='Families purchase the entire activity in full, up front. Students are automatically enrolled in all activity dates.' onCheck={onClickSeason} hidden={common['rates.season.rate'].hidden}>
              {/* Section splits the fields horizontally */}
              <Section>
                <Part {...common['rates.season.rate']} />
                {basicSeasonHomeroomFeeIncrementField}
              </Section>
            </Part>
            <Part label='Season price by number of days/week' checkbox checked={rateVisibilityState.enabled.seasons} onCheck={onClickSeasonByDaysPerWeek} hidden={common['rates.seasons'].hidden}>
              <ConfigurableSeasonRates seasonsFields={common} />
            </Part>
          </Part>
        }, !common['rates.usage.rate'].hidden && {
            label: 'Usage',
            content: <Part>
              <Section>
                <Part {...common['rates.usage.rate']} infoTip='Families enroll and enter their payment information but are not charged up front. Students get added to an activity date when attendance is taken and are billed dynamically based on days or hours used.' />
                <Part {...common['rates.usage.unit']} />
                <Part {...common['rates.usage.roundingIncrement']} />
                <Part {...common['rates.usage.gracePeriod']} />
                <Part {...common['rates.usage.homeroomFeeRoundingIncrementCents']} />
              </Section>
            </Part>
          }
        ]} />
      </Section>,
      schoolFee: siteRole 
        ? <Section label="School fee" hidden={behavior?.fields?.pricing?.none} component={
          <VBox>
            {props.editing && <Subtitle2 mb="$30">Updating the fee will only change the fee for this activity. If the school fee is changed in your settings, it will not override this change.</Subtitle2>}
            <SchoolFeeCombo fees={breakdowns?.season} fieldsConfig={common} />
          </VBox>
        } />
        : undefined,
      breakdown: siteRole 
        ? undefined
        : CourseUtils.usingBasicSeasonRateOrPrice(form?.values?.rates) ? <FeeBreakdown {...breakdowns?.season} /> : undefined,

      hideable: <Section {...common.hideable} />
    }
  }

  function onChangeRateType(type:number) {
    setRateType(type);
    form.setValue('rates', {dropIn:{}, season: {}, seasons: [], recurring:[], usage:[]});
  }

  function onClickSubscription() {
    form.setValue('rates.recurring' as any, rateVisibilityState.enabled.recurring ? [] : [{rate:0}]);
  }

  function onClickDropIn() {
    form.setValue('rates.dropIn.rate' as any, rateVisibilityState.enabled.dropIns ? null : 0);
  }

  function onClickSeason() {
    form.setValue('rates.season.rate' as any, rateVisibilityState.enabled.season ? null : 0);

    if (rateVisibilityState.enabled.season) {
      form.setValue('rates.season.materialsRate' as any, null);
    } 
  }

  function onClickSeasonByDaysPerWeek() {
    form.setValue('rates.seasons' as any, rateVisibilityState.enabled.seasons ? [] : [{rate:0}]);
  }

  function onToggleStartDateDisabled() {
    const startDateDisabled = form.values.rates.recurring[0].startDateDisabled;
    const recurring = form.values.rates.recurring.map(r => ({ ...r, startDateDisabled: !startDateDisabled }));
    form.setValue([], 'rates.recurring' as any, recurring);
  }

  return render();
}
