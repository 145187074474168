import * as React from 'react';
import moment from 'moment';
import { isEqual } from 'lodash-es';

import { Enrollment, DiscountFormat, EnrollmentUtils, PriceConfigKind, RecurringPriceConfig } from 'app2/api';
import { Modal, Form, Section, FormModel, useForm, RepeatingSection, Field } from 'app2/components';
import { errorPathTransform, StudentNameField } from 'app2/views/shared';
import { EnrollmentConfigurationForm, EnrollmentForm, getEnrollmentConfig, getEnrollmentConfigFromPriceConfig, useCourseDates } from 'app2/views/shared-public';

import { DiscountComboAndBreakdown } from './price-config';
import { promoteEnrollments, PromoteEnrollmentsMutationVariables } from './generated';

import { CourseSelections } from '../../generated';

interface FormValues extends EnrollmentForm {
  format?: DiscountFormat;
  rate?: number;
  enrollments: Pick<Enrollment, 'id' | 'priceConfig'>[];
}

type PromoteForm = FormModel<FormValues>;

interface Props {
  course: CourseSelections;
  enrollments: Pick<Enrollment, 'id' | 'priceConfig' | 'course'>[];
}

export function PromoteModal(props: Props) {
  const protoEnrollment = props.enrollments[0];
  const hasRecurring  = EnrollmentUtils.usingRecurring(protoEnrollment);
  const protoConfigRecurring = EnrollmentUtils.findPriceConfig(protoEnrollment, config => config.kind == PriceConfigKind.Recurring) as RecurringPriceConfig;
  const dates = useCourseDates(protoEnrollment.course);
  const startDate = (dates.start?.isAfter(moment()) ? dates.start : dates.sessions?.find(s => s.isSameOrAfter(moment())) || dates.start)?.format();
  const form = useForm<FormValues>({ enrollments: props.enrollments, format: null, rate: 0, recurring: { ...protoConfigRecurring, startDate } }, [props.enrollments], {alwaysSave: true});
  const formConfig = getEnrollmentConfig(form);
  const homogenous = React.useMemo(() => {
    const first = props.enrollments[0];
    return props.enrollments.length > 0 && props.enrollments.every(other => isEqual(first.priceConfig, other.priceConfig));
  }, [props.enrollments]);

  function render() {
    return <Modal title='Promote' ok='Promote'>{renderForm()}</Modal>;
  }

  function renderForm() {
    return <Form icon="User" title="Promotion options" form={form} onOk={handleSubmit} onNavigation='nothing' editing alwaysSave>
      <RepeatingSection name="enrollments" bordered fields={[<Field label="Student" name="student.name" readOnly nameKind='full' component={StudentNameField}  />]} />
        {homogenous
          ? <>
              {hasRecurring && <EnrollmentConfigurationForm course={props.course} form={form} recurring={{startDate: true}} kind={PriceConfigKind.Recurring} />}
              <DiscountComboAndBreakdown parentCourse={props.course} course={props.course} config={hasRecurring ? formConfig : getEnrollmentConfigFromPriceConfig(protoEnrollment.priceConfig)}/>
            </>
          : <Section label="Adjust price">'Discounts can not be applied to students that have different prices.  Select individual students (or students with identical price plans) to apply discounts, or click Promote to promote them without a discount.'</Section>
        }
    </Form>
  }

  async function handleSubmit(form: PromoteForm) {
    const ids = props.enrollments.map(e => e.id);
    const variables: PromoteEnrollmentsMutationVariables = { courseId: props.course?.id, ids };

    if (form.values.format) {
      variables.discount = { format: form.values.format, rate: form.values.rate };
    }

    if (homogenous && hasRecurring && formConfig?.recurring?.startDate) {
      variables.startDate = form.values.recurring.startDate;
    }

    const [success] = await promoteEnrollments({
      variables,
      successMsg: 'Students promoted',
      error: { handler: form, transform: [errorPathTransform('ids', 'enrollments'), errorPathTransform("discount.format", "format"), errorPathTransform("discount.rate", "rate")] }
    });
    return success;
  }

  return render();
}
