import * as React from 'react';
import { useParams } from 'react-router';

import { DropdownField, InputField, Panel, Part, Section, useForm, useFormToUrl } from 'app2/components';

import { historyTypes, MutationChangesPanel } from '../shared';
import { AdminPage } from '../AdminPage';

import { AdminHistoryRouteParams } from './AdminHistoryRouteParams';

export function MutationChanges() {
  const { type, id } = useParams<AdminHistoryRouteParams>();
  const form = useForm({type, id}, []);
  useFormToUrl(form, {path: '/admin2/mutation-changes/:type/:id'});

  function render() {
    return <AdminPage title='Mutations'>
        <Panel type='edit-no-save-button' form={form} onNavigation='nothing'>
          <Section>
            <Part label='Type' name='type' component={DropdownField} options={historyTypes} />
            <Part label='ID' name='id' component={InputField} />
          </Section>
        </Panel>
        <MutationChangesPanel type={form.values.type} id={form.values.id} />
    </AdminPage>
  }

  return render();
}
