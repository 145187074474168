import * as React from 'react';
import { useParams } from 'react-router';

import { Panel, Tabs} from 'app2/components';

import { AdminPage } from '../AdminPage';
import { MutationChangesPanel, HistoryVersions } from '../shared';

import { Enrollments } from './Enrollments';
import { Reviews } from './Reviews';
import { useAdminCourseQuery } from './generated';

export function Course() {
  const { course } = useParams<{course:string}>();
  const [result] = useAdminCourseQuery({variables:{id:course}});
  const item = result.data?.course;

  function render() {
    return <AdminPage title='Course'>
      <Panel icon='Activity' title={`${item?.name} (${course})`} initialValues={item} type='display'>
        <Tabs baseUrl={`/admin2/activities/${course}/:tab`} urlParameter='tab' tabs={[
            {label:'Enrollments', name: 'enrollments', content: <Enrollments />}, 
            {label:'Reviews', name: 'reviews', content: <Reviews />}, 
            {label:'History', name:'mutations', content: <MutationChangesPanel type='Course' id={course} />},      
            {label:'History (dont use)', name:'history', content: <HistoryVersions type='Course' id={course} />},      
        ]} />
      </Panel>
    </AdminPage>
  }

  return render();
}
