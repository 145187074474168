import * as React from 'react';

import { Link, FieldInfo } from 'app2/components'

interface Props {
  info?:FieldInfo<{id:string}>;
  id?:string;
}

export function MutationLink(props:Props) {
  const id = props.id || props.info?.record?.id;

  return <Link className='hr-value' text='body' whiteSpace='nowrap' to={`/admin2/mutations/${id}`}>{id}</Link>
}

MutationLink.fieldProps = {
  fieldProperty: 'info',
}