import * as React from 'react';

import { EnrollmentStatusFilter, authorizedCoursePreferenceKeys } from 'app2/api';
import { DataTable, useRef } from 'app2/components';
import { CopyParentEmailsAction } from 'app2/views/shared';

import { CourseSelections } from '../../generated';

import { EnrollmentsTable } from '../EnrollmentsTable';
import { renderMoveButton, renderPromoteButton, renderRemoveStudentsButton, renderResendStudentsButton } from '../actions';
import { useCourseEnrollmentCols } from '../useCourseEnrollmentCols';

import { WaitlistedEnrollmentsSelections, useWaitlistedEnrollmentsQuery } from './generated';

const TABLE_PREFS_VERSION = '5';

interface Props {
  course: CourseSelections;
}

export function Waitlisted(props: Props) {
  const cols = useCourseEnrollmentCols<WaitlistedEnrollmentsSelections>(colNames, props.course, EnrollmentStatusFilter.Waitlisted);
  const tableRef = useRef<DataTable<WaitlistedEnrollmentsSelections>>();

  function render() {
    return (
      <EnrollmentsTable<WaitlistedEnrollmentsSelections>
        course={props.course}
        header={{ icon: 'Clock', title: 'Waitlisted', options: renderOptions(), secondaryActions: renderSecondaryActions() }}
        table={{ cols, ref: tableRef, none: 'No waitlisted students' }}
        prefsVersion={TABLE_PREFS_VERSION}
        prefsKey={authorizedCoursePreferenceKeys.waitlistedEnrollmentsTable}
        queryHook={useWaitlistedEnrollmentsQuery}
      />
    );
  }

  function renderOptions() {
    return [<CopyParentEmailsAction table={tableRef.current} />];
  }

  function renderSecondaryActions() {
    return [
      renderPromoteButton(props.course, tableRef.current),
      renderMoveButton(props.course, tableRef.current),
      renderRemoveStudentsButton(props.course, tableRef.current, true),
      renderResendStudentsButton(props.course, tableRef.current)
    ];
  }

  return render();
}

const colNames = [
  'student.firstName',
  'student.lastName',
  'student.grade',
  'rosterPeriods',
  'student.classroom.displayName',
  'dismissal',
  'parent.name',
  'parent.phone',
  'parent.email',
  'groups'
];