import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminCourseEnrollmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminCourseEnrollmentsQuery = {
  course?:
    | {
        id: string;
        name?: string | null | undefined;
        enrollments?:
          | Array<{ id: string; createdAt: string; status: Types.EnrollmentStatusEnum; student?: { name: string; parents: Array<{ id: string; name?: string | null | undefined }> } | null | undefined } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const AdminCourseEnrollmentsDocument = gql`
  query AdminCourseEnrollments($id: ID!) {
    course(course: $id) {
      id
      name
      enrollments(sorts: [{ name: "createdAt", direction: DESC }]) {
        id
        createdAt
        status
        student {
          name
          parents {
            id
            name
          }
        }
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminCourseEnrollmentsArgs = Omit<UseQueryArgs<AdminCourseEnrollmentsQueryVariables, AdminCourseEnrollmentsQuery>, 'query'>;

export function useAdminCourseEnrollmentsQuery(options: AdminCourseEnrollmentsArgs = {}) {
  return useQuery<AdminCourseEnrollmentsQueryVariables, AdminCourseEnrollmentsQuery>({ query: AdminCourseEnrollmentsDocument, ...options });
}

export type AdminCourseEnrollmentsOptions = ExecQueryOptions<AdminCourseEnrollmentsQueryVariables>;

export function adminCourseEnrollments(options: AdminCourseEnrollmentsOptions) {
  return executeQuery<AdminCourseEnrollmentsQuery, AdminCourseEnrollmentsQueryVariables>(AdminCourseEnrollmentsDocument, options);
}

export type AdminCourseEnrollmentsSelections = AdminCourseEnrollmentsQuery['course'];
