import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminStudentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type AdminStudentQuery = { student?: { id: string; name: string; parents: Array<{ id: string; name?: string | null | undefined }> } | null | undefined };

export const AdminStudentDocument = gql`
  query AdminStudent($id: ID!) {
    student(student: $id) {
      id
      name
      parents {
        id
        name
      }
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminStudentArgs = Omit<UseQueryArgs<AdminStudentQueryVariables, AdminStudentQuery>, 'query'>;

export function useAdminStudentQuery(options: AdminStudentArgs = {}) {
  return useQuery<AdminStudentQueryVariables, AdminStudentQuery>({ query: AdminStudentDocument, ...options });
}

export type AdminStudentOptions = ExecQueryOptions<AdminStudentQueryVariables>;

export function adminStudent(options: AdminStudentOptions) {
  return executeQuery<AdminStudentQuery, AdminStudentQueryVariables>(AdminStudentDocument, options);
}

export type AdminStudentSelections = AdminStudentQuery['student'];
