import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminMutationChangesQueryVariables = Types.Exact<{
  type: Types.Scalars['String'];
  id: Types.Scalars['ID'];
}>;

export type AdminMutationChangesQuery = {
  mutationChanges?:
    | Array<{
        id: string;
        mutationId: string;
        displayName?: string | null | undefined;
        name?: string | null | undefined;
        ip?: string | null | undefined;
        lr?: string | null | undefined;
        start?: string | null | undefined;
        end?: string | null | undefined;
        variables?: { [key: string]: any } | null | undefined;
        targetChanges?: { [key: string]: any } | null | undefined;
        kind?: string | null | undefined;
        user?: { id: string; name?: string | null | undefined } | null | undefined;
      }>
    | null
    | undefined;
};

export const AdminMutationChangesDocument = gql`
  query AdminMutationChanges($type: String!, $id: ID!) {
    mutationChanges(type: $type, id: $id) {
      id
      mutationId
      displayName
      name
      user {
        id
        name
      }
      ip
      lr
      start
      end
      variables
      targetChanges
      kind
    }
  }
`;

import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type AdminMutationChangesArgs = Omit<UseQueryArgs<AdminMutationChangesQueryVariables, AdminMutationChangesQuery>, 'query'>;

export function useAdminMutationChangesQuery(options: AdminMutationChangesArgs = {}) {
  return useQuery<AdminMutationChangesQueryVariables, AdminMutationChangesQuery>({ query: AdminMutationChangesDocument, ...options });
}

export type AdminMutationChangesOptions = ExecQueryOptions<AdminMutationChangesQueryVariables>;

export function adminMutationChanges(options: AdminMutationChangesOptions) {
  return executeQuery<AdminMutationChangesQuery, AdminMutationChangesQueryVariables>(AdminMutationChangesDocument, options);
}

export type AdminMutationChangesSelections = AdminMutationChangesQuery['mutationChanges'][0];

(AdminMutationChangesDocument as any).additionalTypenames = ['MutationChange'];
