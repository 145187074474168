import * as React from 'react';
import { useParams } from 'react-router';

import { Tabs} from 'app2/components';

import { AdminPage } from '../AdminPage';
import { MutationChangesPanel, HistoryVersions } from '../shared';

import { Enrollments } from './Enrollments';
import { StudentInfo } from './StudentInfo';

export function Student() {
  const { id } = useParams<{id:string}>();

  function render() {
    return <AdminPage title='Student'>
      <Tabs baseUrl={`/admin2/students/${id}/:tab`} urlParameter='tab' tabs={[
        {label:'Info', name: 'info', content: <StudentInfo />}, 
        {label:'Enrollments', name: 'enrollments', content: <Enrollments />}, 
        {label:'History', name:'mutations', content: <MutationChangesPanel type='Student' id={id} />},      
        {label:'History (dont use)', name:'history', content: <HistoryVersions type='Student' id={id} />},
      ]} />
    </AdminPage>
  }

  return render();
}
