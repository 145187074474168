import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  Heading1,
  Heading2,
  Subtitle1,
  Subtitle2,
  Box,
  VBox,
  HBox,
  Button,
  Link,
  Icon,
  Img,
  Loader
} from 'app2/components';
import { observer } from 'app/observable';
import { sharedDataManager } from 'app/admin/api';
import { CatalogListingStore } from 'app2/api';
import { UserUtils, eventManager } from 'app2/api';
import { buildCloudinaryImageUrl } from 'app2/components';
import {
  COURSE_CARD_IMAGE_WIDTH,
  COURSE_CARD_IMAGE_HEIGHT,
  DEFAULT_COURSE_CARD_IMG
} from 'app2/views/shared-public';

import { PublicPage } from '../../shared-public';

import { HowItWorks } from './HowItWorks';
import { ContactProvider } from './ContactProvider';

type CatalogListingProps = RouteComponentProps<{ slug: string }>;

@observer
export class InnerCatalogListing extends React.Component<CatalogListingProps> {
  store: CatalogListingStore = sharedDataManager.get(CatalogListingStore);

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(_: CatalogListingProps) {
    this.loadData();
  }

  async loadData() {
    try {
      const loaded = await this.store.load(this.props.match.params.slug);

      if (loaded) {
        this.logPageView();
      }
    } catch (_) {}
  }

  logPageView() {
    eventManager.log('viewCatalogListingPage', {
      url: window.location.href,
      catalogListingName: this.store.catalog.name,
      providerName: this.store.catalog.provider.name,
      category: this.store.catalog.category,
      catalogImageUploaded: Boolean(
        this.store.catalog.course_card_image
      ).toString(),
      providerImageUploaded: Boolean(
        this.store.catalog.provider.profile_photo
      ).toString()
    });
  }

  render() {
    if (!this.store.catalog) {
      return <Loader />;
    }

    const crumbs = [
      {
        label: this.store.catalog.name,
        url: `/catalog/${this.store.catalog.slug}`
      }
    ];
  
    return <PublicPage head={this.renderHead()} crumbs={crumbs} useMaxWidth header footer>
      <Header store={this.store} />
      <Overview store={this.store} />
      <Provider store={this.store} />
      <HowItWorks />
    </PublicPage>
  }

  renderHead() {
    const title = `${this.store.catalog.name} - After School Activity for Kids`;
    const description = this.store.catalog.description;

    return {title, description, image:buildCloudinaryImageUrl(this.store.catalog.course_card_image || DEFAULT_COURSE_CARD_IMG)};
  }
}

interface Props {
  store: CatalogListingStore;
}

function Header(props: Props) {
  const imageURL = buildCloudinaryImageUrl(
    props.store.catalog.course_card_image || DEFAULT_COURSE_CARD_IMG,
    {
      width: COURSE_CARD_IMAGE_WIDTH,
      height: COURSE_CARD_IMAGE_HEIGHT,
      borderRadius: 10
    }
  );

  function handleBringToMySchoolClick() {
    eventManager.log('clickBringToMySchool', {
      url: window.location.href,
      catalogListingName: props.store.catalog.name,
      providerName: props.store.catalog.provider.name,
      category: props.store.catalog.category,
      catalogImageUploaded: Boolean(
        props.store.catalog.course_card_image
      ).toString(),
      providerImageUploaded: Boolean(
        props.store.catalog.provider.profile_photo
      ).toString()
    });
  }

  return <Box layout={['vbox', 'hbox']} mb="$30" width="100%" itemFlex="1">
      <VBox hAlign="left" pb={['40px', 'unset']}>
        <Heading1 mb="$15">{props.store.catalog.name}</Heading1>
        <HBox mb="$40">
          <Heading2 mr="$10">Taught by:</Heading2>
          <Link
            to={`/provider/${props.store.catalog.provider.slug}/about`}
            target="_blank"
            text="heading2"
          >
            {props.store.catalog.provider.name}
          </Link>
        </HBox>
        <Subtitle1 mb="$10">
          Like this activity? Bring it to your school!
        </Subtitle1>
        <ContactProvider provider={{display_name:props.store.catalog.provider.name, id:props.store.catalog.provider.slug}} label='Bring to my school' onClick={handleBringToMySchoolClick} />
      </VBox>
      <VBox
        minWidth={['100%', '50%']}
        borderRadius="standard"
        pl={['unset', '$40']}
      >
        <Img src={imageURL} width="100%" />
      </VBox>
    </Box>
}

function Overview(props: Props) {
  const catalog = props.store.catalog;
  const supplies = catalog.supplies;

  return (
    <VBox mb="$40" width="100%">
      <Heading2
        pb="$10"
        borderBottom="solid 2px"
        borderColor="primary"
        width="fit-content"
      >
        Overview
      </Heading2>
      <Box layout={['vbox', 'hbox']} my="$40" itemFlex="1" width="100%">
        <VBox pb={['40px', 'unset']}>
          <Subtitle1 mb="$10">Description</Subtitle1>
          <Subtitle2 whiteSpace="pre-wrap">{catalog.description}</Subtitle2>
        </VBox>
        <VBox minWidth={['100%', '50%']} pl={['unset', '$40']}>
          <VBox boxShadow="box" p="$15" pb="$30">
            <Subtitle1 mb="$30">Supplies</Subtitle1>
            {supplies && supplies.length ? (
              supplies.map((supply, index) => (
                <HBox key={index} mb="$15" text="subtitle2">
                  <Icon name="CheckSquare" mr="$15" />
                  {supply}
                </HBox>
              ))
            ) : (
              <HBox text="subtitle2" mb="$15">
                No supplies needed. All supplies will be provided in class.
              </HBox>
            )}
          </VBox>
        </VBox>
      </Box>
    </VBox>
  );
}

function Provider(props: Props) {
  const provider = props.store.catalog.provider;

  function handleProviderLinkClick() {
    eventManager.log('clickVisitProviderPage', {
      url: window.location.href,
      catalogListingName: props.store.catalog.name,
      providerName: props.store.catalog.provider.name,
      providerImageUploaded: Boolean(
        props.store.catalog.provider.profile_photo
      ).toString()
    });
  }

  return (
    <>
      <Heading2 mb="$10">Provider</Heading2>
      <Box layout={['vbox', 'hbox']} mb="$40" width="100%">
        <ProfilePhoto store={props.store} />
        <VBox hAlign="left">
          <Subtitle1 mb="$15">{provider.name}</Subtitle1>
          <Subtitle2 mb="$10" fontWeight="bold">
            Provider description
          </Subtitle2>
          <Subtitle2 mb="$30" whiteSpace="pre-wrap">
            {provider.about}
          </Subtitle2>
          <Link
            to={`/provider/${props.store.catalog.provider.slug}/about`}
            target="_blank"
            onClick={handleProviderLinkClick}
          >
            <Button>Visit provider page</Button>
          </Link>
        </VBox>
      </Box>
    </>
  );
}

function ProfilePhoto(props: Props) {
  const provider = props.store.catalog.provider;
  const hasProfilePhoto =
    provider.profile_photo && provider.profile_photo.indexOf('http') == -1;
  const background = hasProfilePhoto
    ? `url(${buildCloudinaryImageUrl(provider.profile_photo, {
        borderRadius: 'max'
      })})`
    : undefined;
  const bg = !hasProfilePhoto ? 'brandDark' : undefined;
  const initials = !hasProfilePhoto ? UserUtils.getInitials(provider.name, / |-/g) : '';

  return (
    <HBox
      text="subtitle1"
      color="white"
      hAlign="center"
      vAlign="center"
      minWidth="124px"
      maxWidth="124px"
      height="124px"
      borderRadius="62px"
      bg={bg}
      background={background}
      backgroundSize="124px 124px"
      mr={['unset', '$40']}
      mb={['$40', 'unset']}
    >
      {initials}
    </HBox>
  );
}

export const CatalogListing = withRouter(InnerCatalogListing);
