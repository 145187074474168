import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminSeasonUpdateMutationVariables = Types.Exact<{
  attributes: Types.SeasonInput;
}>;

export type AdminSeasonUpdateMutation = { seasonUpsert?: { season?: { id: string } | null | undefined } | null | undefined };

export const AdminSeasonUpdateDocument = gql`
  mutation AdminSeasonUpdate($attributes: SeasonInput!) {
    seasonUpsert(attributes: $attributes) {
      season {
        id
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminSeasonUpdateOptions = ExecMutationOptions<AdminSeasonUpdateMutationVariables>;

export function adminSeasonUpdate(options: AdminSeasonUpdateOptions) {
  return executeMutation<AdminSeasonUpdateMutation, AdminSeasonUpdateMutationVariables>(AdminSeasonUpdateDocument, options);
}
